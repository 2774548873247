/*search-area-start*/
.page-container {

  .carouse-container {
    margin-bottom: 20px;
  }

  .carousel {
    padding-bottom: 52px;

    .carousel-inner {
      padding-top: 17px;
    }

    .carousel-controls {
      margin-bottom: 15px;
    }

    .news-stories-area {
      .news-story-media img {
        width: 100%;
        height: 250px;
      }
    }

    .story-heading {
      height: 91px;
    }

    .news-story-content {
      height: 159px;
      &.video-story-text{
        height: 46px !important;
      }

      .news-story-text.stock-info-small-row {
        height: 100%;
        position: relative;

        .snap-quote.negative-movement, .snap-quote.positive-movement {
          position: absolute;
          bottom: 0;
        }
      }
    }
  }

  .page-header {
    background: theme-color('gray-dark');
    .breadcrumb {
      padding: 0;
      background: inherit;
      a, a:visited {
        color: theme-color('secondary');
        text-decoration: none;
        font-size: 13px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    h4 {
      font-size: 20px;
    }
  }

  .input-group {
    border-radius: 5px;
    margin-bottom: 14px;
    .news-main-search {
      height: 55px;
      border: 0;
      padding: 0 15px;
    }

    input {
      border-radius: 5px 0 0 5px !important;
    }

    .news-main-search,
    .input-group-append {
      background: #F1F1F1 0 0 no-repeat padding-box;
    }

    .input-group-append {
      border-radius: 0 5px 5px 0 ;
    }

    ::placeholder {
      text-align: left;
      letter-spacing: 0;
      color: #383838;
      opacity: 1;
      font-family: "Roboto", "sans-serif";
      font-size: 20px;
      font-weight: bold;
    }
  }

}

