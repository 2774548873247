@import "../mixins.scss";
@import "../variables.scss";
@import "src/styles/theme";
@import "~bootstrap/scss/mixins/breakpoints";
body {
    &.mobile-menu-open {
        overflow: hidden;
    }
}

.main-wrapper {
    background-color: #f1f1f1;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.22);
    &.container {
        padding: 0;
    }
}

header {
    background-color: #fff;

    > .row {
        background-color: #fff;
    }

    .stock-latest {
        overflow: hidden;

        @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
            display: none;
        }

        .inner {
            border-bottom: 1px solid rgb(235, 235, 235);
            justify-content: space-between;
        }

        .stock-holder {
            font-size: 12px;
            line-height: 22px;
            display: flex;
            white-space: nowrap;

            @media screen and (max-width: map-get($grid-breakpoints, xl) - 1) {
                + .stock-holder {
                    margin-left: 10px;
                }
            }

            &:first-child {
                margin: 0;
            }

            &.positive-movement {
                .stock-value-movement {
                    color: var(--green);
                }
            }

            &.negative-movement {
                .stock-value-movement {
                    color: var(--pink);
                }
            }

            > span + span {
                margin-left: 3px;
            }

            .stock-name {
                color: var(--blue);
                margin-right: 4px;
            }

            .stock-value-movement {
                margin-left: 8px;
            }

            .move-arrow-icon {
                max-width: 11px;
                top: -1px;
                position: relative;
            }
        }
    }

    .custom-toggler {
        border: none;
        background-color: transparent;

        &.btn-menu {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 16px;
            background-color: transparent;
            color: inherit;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 6px;
            cursor: pointer;
            transition: 0.3s ease;
            margin-left: 16px;

            &:focus {
                outline: none;
            }

            > .btn-menu {
                &__text {
                    margin-left: 10px;
                    font-size: 1.125rem;
                    font-weight: 700;
                    line-height: 1;
                }

                &__bars {
                    display: block;
                    position: relative;
                    width: 20px;
                    height: 2px;
                    background-color: var(--blue);
                    transition: 0.3s;

                    &:before,
                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: var(--blue);
                        transition: 0.3s;
                    }

                    &:before {
                        transform: translate(0, -7px);
                    }

                    &:after {
                        transform: translate(0, 7px);
                    }
                }
            }
        }

        // .navbar-toggler-icon {
        //     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 20 25'%3e%3cpath stroke='rgba(50, 110, 172, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        // }
    }

    .navbar.main-nav {
        height: 50px !important;
        align-items: center !important;
        @media screen and (max-width: map-get($grid-breakpoints, xl) - 1) {
            height: unset !important;
            padding: 8px 16px;
        }
        // background: rgb(50, 110, 172);
        // background: linear-gradient(90deg, rgba(50, 110, 172, 1) 0%, rgba(28, 216, 158, 1) 100%);
        background: transparent linear-gradient(92deg, #326eac 0%, #519ae5 100%) 0% 0% no-repeat;

        @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
            padding: 5px 15px;
        }

        .nav-home-icon {
            position: relative;
        }

        .navbar-nav {
            width: 100%;
            align-items: center;

            .nav-item {
                font-weight: bold;
                font-size: 17px;
                color: #fff;
                line-height: 19px;
                padding: 0px;
                margin-right: 16px;
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.16);
                border-bottom: 2px solid transparent;
                transition: border-bottom-color $main-hover-transition;

                &:hover {
                    border-bottom: 2px solid;
                }

                &.active {
                    border-bottom: 2px solid white;
                }

                &.my-finanztreff {
                    margin-left: auto;
                    margin-right: 0;
                }
            }
        }

        @media screen and (max-width: map-get($grid-breakpoints, xl) - 1) {
            .navbar-collapse {
                // position: absolute;
                // z-index: 100;
                // top: 0px;
                // left: 0px;
                // width: 100%;
                justify-content: flex-end;
                max-width: 360px;

                .navbar-nav {
                    max-width: 360px;
                    background: linear-gradient(142deg, #327dcb 0%, #6eb3f9 100%) 0% 0% no-repeat transparent;

                    .nav-item {
                        line-height: 60px;
                        width: 100%;
                        margin: 0;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                        padding: 0 16px;
                        position: relative;

                        &::after {
                            content: "";
                            position: absolute;
                            width: 50px;
                            height: 61px;
                            top: 0;
                            right: 0;
                            z-index: 10;
                            // background-image: url("/static/img/svg/icon_direction_right_white.svg");
                            background-position: center center;
                            background-repeat: no-repeat;
                            transform: rotate(90deg);
                        }

                        &:last-child {
                            border: 0;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: map-get($grid-breakpoints, xl) - 1) {
        position: relative;

        .navbar.main-nav {
            justify-content: flex-end;
            width: 100%;
            min-height: 10px;
            padding: 0;
            left: 0;
            position: absolute;
            z-index: 50;
            // overflow-y: scroll;
            // overflow-x: hidden;
            // max-height: calc(100vh - 75px);

            .navbar-collapse {
                max-width: none;
                text-align: right;
                overflow: hidden;
                overflow-y: scroll;

                .navbar-nav {
                    text-align: left;
                    display: inline-block !important;
                    height: calc(100vh - 75px);
                }
            }
        }
    }

    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
        .top-section {
            > .container {
                padding-left: 8px;
                padding-right: 8px;
            }
        }

        .navbar.main-nav {
            max-height: calc(100vh - 52px);
            background: linear-gradient(145deg, #326eac 0%, #519ae5 100%) 0% 0% no-repeat transparent;

            .navbar-collapse {
                text-align: left;

                .navbar-nav {
                    display: inline-block !important;
                    height: 120%;
                }
            }
        }
    }
}

footer {
    margin-top: 150px;
    background-color: $dark-background;
    border: 1px solid #707070;
    color: white;
    padding: 24px 32px;
    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
        padding: 24px 8px;

    }
    .version{
        margin-top: 66px;
        color: #fff;
        opacity: 0.43;
        @media screen and (max-width: map-get($grid-breakpoints, xl) - 1) {
            margin-top: 70px;
        }
    }
}

.changelog-content, .info-content{
    min-height: 700px;
    @media screen and (max-width: map-get($grid-breakpoints, xl) - 1) {
        min-height: 800px;
    }
    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
        min-height: 530px;
    }
}

.top-bar {
    padding: 16px 0;
    justify-content: space-between;

    @media screen and (max-width: map-get($grid-breakpoints, xl) - 1) {
        padding: 8px 0;
    }

    .account-search {
        line-height: 36px;
        font-size: 15px;
        display: flex;

        .greetings {
            color: var(--blue);

            .acc-name {
                font-weight: bold;
            }
        }

        .acc-butt {
            margin-left: 12px;
            cursor: pointer;
        }

        .search-butt {
            margin-left: 10px;
            cursor: pointer;
        }
    }
}

.main-logo {
    @media screen and (max-width: map-get($grid-breakpoints, xl) - 1) {
        .navbar-brand {
            margin: 0;
            padding: 0;
        }
    }

    //img {
    //    max-width: 138px;
    //}
}

.horizontal-bar-movement.progress {
    position: relative;
    border-radius: 0;
    background: none;

    &.height-small {
        height: 5px;
    }

    &.height-medium {
        height: 8px;
    }

    &.height-medium-big {
        height: 11px;
    }

    &.height-big {
        height: 20px;
    }

    &.with-floating-percents {
        overflow: visible;

        & + .bar-asset-info {
            margin-top: 5px;
            font-size: 14px;
        }

        .progress-bar {
            overflow: visible;
        }

        .h-bar-pointer-floating {
            height: auto;
            width: auto;
            position: absolute;
            top: -10px;
            z-index: 1;
            font-weight: bold;
            font-size: 16px;

            .pointer-name {
                padding-left: 1px;
                padding-bottom: 5px;
            }

            .floating-pointer {
                border: 2px solid white;
                background-color: #383838;
                height: 15px;
                width: 2px;
                box-sizing: content-box;
            }
        }
    }

    &.with-floating-text {
        overflow: visible;

        &.height-big {
            .h-bar-pointer-floating {
                &.top-pointer {
                    top: -10px;
                }

                &.bottom-pointer {
                    bottom: -18px;
                }
            }
        }

        .progress-bar {
            overflow: visible;
        }

        .h-bar-pointer-floating {
            height: auto;
            width: auto;
            position: absolute;
            z-index: 1;

            .pointer-name {
                padding-left: 1px;
                padding-bottom: 5px;
            }

            .floating-pointer {
                border: 2px solid white;
                background-color: #383838;
                height: 15px;
                width: 2px;
                box-sizing: content-box;
            }
        }
    }

    &.just-pointer {
        overflow: visible;

        &.height-medium-big {
            .h-bar-pointer-floating {
                &.top-pointer {
                    top: -10px;
                }

                &.bottom-pointer {
                    bottom: -18px;
                }

                &.medium-pointer {
                    top: -4px;
                }

                .floating-pointer {
                    height: 19px;
                }
            }
        }

        &.height-small {
            .h-bar-pointer-floating {
                &.top-pointer {
                    top: -10px;
                }

                &.bottom-pointer {
                    bottom: -18px;
                }

                &.medium-pointer {
                    top: -5px;
                }

                .floating-pointer {
                    height: 15px;
                }
            }
        }

        .progress-bar {
            overflow: visible;
        }

        .h-bar-pointer-floating {
            height: auto;
            width: auto;
            position: absolute;
            z-index: 1;

            .pointer-name {
                padding-left: 1px;
                padding-bottom: 5px;
            }

            .floating-pointer {
                &.no-border {
                    border: none;
                }

                &.width-3 {
                    width: 3px;
                    position: relative;
                    right: 4px;
                }

                border: 2px solid white;
                background-color: #383838;
                height: 15px;
                width: 2px;
                box-sizing: content-box;
            }
        }
    }

    &.banner-graph {
        overflow: visible;
        // max-width: 47%;
        justify-content: space-between;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        .graph-wrapper {
            flex-grow: 1;
            margin: 0 10px;
        }

        @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
            .graph-wrapper {
                max-width: 220px;
            }
        }

        &.more-padding {
            max-width: 48%;
            padding: 0 60px 0 52px;
        }

        .progress-bar {
            overflow: visible;
        }

        .between-pointer {
            position: absolute;
            z-index: 1;
            font-size: 11px;
            font-weight: 300;
            left: 0;
            text-align: left;
            top: -20px;
            line-height: 1;
            min-width: 50px;
            height: 25px;
            padding: 0px 4px;
            border-left: 1px dotted;
        }

        .graph-legend {
            position: relative;
            line-height: 1.3;
            font-size: 11px;
            top: -10px;

            &.left-p {
                text-align: left;
            }

            &.right-p {
                text-align: right;
            }

            > div {
                white-space: nowrap;
            }
        }
    }

    .progress-bar {
        position: relative;

        &.with-edge-margin {
            margin: 0 5px;
        }
    }
}

main {
    position: relative;

    > .fader {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10;
        width: 100%;
        height: 100%;
        display: none;
    }

    @media screen and (min-width: map-get($grid-breakpoints, xl)) {
        > .fader {
            display: none !important;
        }
    }
}

.banner-advert-wrapper {
    padding-bottom: 5px !important;

    .bottom-text {
        font-size: 10px;
        color: #00000066;
        text-transform: uppercase;
        font-weight: bold;
        padding-top: 5px;
    }
}

.banner-advert-holder {
    &.neg-margin {
        margin-left: -5px;
        margin-top: -5px;
    }

    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
        &.neg-margin {
            margin: 0;
        }
    }
}

.tools-hr-bar {
    border: 1px solid #0000000f;
    padding: 5px;
    margin-bottom: 16px;

    .product-type {
        font-size: 12px;
        display: inline-block;
        margin-bottom: 22px;
        text-transform: uppercase;
        color: white;
        padding: 5px 9px;
        border-radius: 2px;
        line-height: 1;
    }

    .bar-holder {
        padding-bottom: 20px;

        .pointer-name {
            color: $body-color;
        }

        .bottom-pointer {
            font-size: 12px;

            &.single-pointer {
                width: 100%;
                text-align: center;
            }
        }
    }

    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
        margin-bottom: 0 !important;
        margin-top: 8px;
    }
}

.vertical-middle-float-border {
    position: absolute;
    top: 0;
    right: 50%;
    width: 2px;
    height: 100%;
    z-index: 1;
}

table {
    &.stock-table {
        margin: 0;

        thead {
            tr {
                th {
                    &:first-child {
                        background-color: transparent;
                    }

                    line-height: 1;
                    padding: 0.6rem;
                }
            }
        }

        tr {
            th[scope="row"] {
                padding-left: 0;
                padding-right: 0;
                text-align: center;
                vertical-align: middle;
            }

            td {
                padding: 0;
            }
        }

        &.table-spacing-7 {
            border-spacing: 7px 7px;
        }

        &.table-spacing-10 {
            border-spacing: 10px 10px;
        }

        &.table-fixed-cells {
            table-layout: fixed;
            border-collapse: separate;
        }

        .stock-wrapper {
            -webkit-transition: background-color 1s ease-out;
            -moz-transition: background-color 1s ease-out;
            -o-transition: background-color 1s ease-out;
            transition: background-color 1s ease-out;

            @each $color, $value in $stock-move-colors {
                &.bg-stock-#{$color} {
                    background-color: $value;
                }
            }

            padding: 0.7rem 0 0.5rem;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
            margin: -1px;
            color: white;
            font-size: 12px;
            line-height: 1;

            .asset-name {
                font-weight: bold;
                margin-bottom: 0.2rem;
                cursor: pointer;
            }

            .value {
                margin-top: 0.4rem;
                font-size: 22px;
            }
        }
    }

    &.last-with-border {
        tbody {
            tr {
                &:last-child {
                    td {
                        border-bottom-width: 1px;
                        border-bottom-style: solid;
                    }
                }
            }
        }
    }

    &.last-with-border-3 {
        tbody {
            tr {
                &:last-child {
                    td {
                        border-bottom-width: 3px;
                        border-bottom-style: solid;
                    }
                }
            }
        }
    }

    thead {
        tr {
            th {
                &.can-sort {
                    position: relative;
                    cursor: pointer;
                    .sort-arrow {
                        position: absolute;
                        top: 9px;

                        &.direction-top {
                            transform: rotate(-90deg);
                        }

                        &.direction-bottom {
                            transform: rotate(90deg);
                        }

                        > img {
                            width: 25px;
                        }
                    }
                }
            }
        }
    }

    tbody {
        tr {
            td {
                .svg-icon {
                    &.move-arrow {
                        margin-left: 7px;
                    }
                    &.drop-trigger {
                        img {
                            width: 19px;
                        }
                    }
                }

                .timing-info-box {
                    font-size: 11px;
                    font-weight: bold;
                    color: #ffffff;
                    background-color: transparent;
                    border-radius: 2px;
                    display: inline-block;
                    line-height: 1;
                    padding: 1px 4px 0;
                    margin-right: 6px;
                    vertical-align: middle;
                    text-align: center;
                }

                .dropdown-toggle {
                    line-height: 1;

                    &::after {
                        display: none;
                    }
                }

                .dropdown-menu {
                    min-width: 180px;
                    margin-bottom: 12px;
                    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                    -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                    border: none;
                    border-radius: 10px;
                    border-top-left-radius: 0;
                    margin-top: -11px;
                    margin-left: 9px;

                    h4 {
                        font-size: 18px;
                        font-weight: bold;
                        font-family: "Roboto Slab";
                        margin-bottom: 6px;
                    }

                    button {
                        padding: 0 10px;
                        padding-left: 5px;
                        margin-top: 8px;
                        display: inline-flex;
                        justify-content: space-between;
                        align-items: center;

                        .svg-icon {
                            margin-right: 7px;
                        }
                    }
                }

                .three-dots {
                    cursor: pointer;

                    > span {
                        display: inline-block;
                        width: 5px;
                        height: 5px;
                        border-radius: 5px;
                    }

                    &.horizontal {
                        margin-left: 8px;
                        position: relative;
                        top: -4px;
                        display: inline-flex;

                        > span {
                            + span {
                                margin-left: 3px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.button-row {
    button {
        + button {
            margin-left: 8px;
        }
    }
}

.big-card-wrapper {
    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
        overflow-x: scroll;
        display: flex;
        flex-wrap: nowrap;
        padding-bottom: 0;

        > .col {
            width: 230px;
            min-width: 230px;
        }
    }
}

.big-card {
    background: transparent linear-gradient(180deg, #ffffff 0%, #ffffff 1%, #fefefe 38%, #f1f1f1 100%) 0% 0% no-repeat;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border: 1px solid rgba(0, 0, 0, 0.06);

    &.not-raised-card {
        background: none;
        background-color: transparent;
        box-shadow: none;

        .data-row {
            &:nth-child(3) {
                border-top: 1px solid var(--border-gray);
            }
        }

        .bottom-row {
            border-top: 1px solid var(--border-gray);
        }
    }

    &.put-card {
        .top {
            background-color: var(--pink);
        }
    }

    &.middle-card {
        .top {
            background-color: var(--kurs-grau);
        }
    }

    &.call-card {
        .top {
            background-color: var(--green);
        }
    }

    .top {
        padding: 5px 0;
        font-weight: bold;
        text-align: center;
        font-size: 14px;
        color: white;
    }

    .data-row {
        display: flex;
        justify-content: space-between;
        margin-top: 18px;
        padding: 0 16px;

        &:nth-child(2) {
            padding-left: 48px;
        }

        &:nth-child(3) {
            border-top: 1px solid white;
            margin-left: 10px;
            margin-right: 10px;
            padding: 10px 22px 0;
            margin-top: 9px;
        }

        .wkn {
            font-size: 17px;
            font-weight: bold;
        }
    }

    .bottom-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
        padding: 10px 2px;
        border-top: 1px solid white;
    }

    @media screen and (max-width: map-get($grid-breakpoints, xl) - 1) {
        .data-row {
            padding: 0 8px;

            &:nth-child(2) {
                padding: 0 8px;
            }

            &:nth-child(3) {
                // justify-content: space-around;
                margin-left: 0;
                margin-right: 0;
                padding-left: 16px;
                padding-right: 16px;
            }
        }

        .bottom-row {
            padding: 10px 0;
        }
    }

    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
        margin-bottom: 16px;

        .data-row {
            &:nth-child(3) {
                flex-direction: column;
                padding-left: 8px;
                padding-right: 8px;

                > div {
                    &.d-flex {
                        flex-direction: row !important;
                        align-items: center;

                        > span {
                            margin-right: 12px;

                            &:first-child {
                                display: inline-flex;
                                flex: 0 0 120px;
                                min-width: 120px;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.asset-type-tag {
    color: white;
    font-size: 12px;
    padding: 4px 7px 3px;
    // border-radius: 2px;
    text-transform: uppercase;

    &.default {
        background-color: var(--primary);
    }
    &.aktie {
        background-color: var(--dark-blue);
    }
    &.index {
        background-color: var(--yellow);
    }
    &.fonds {
        background-color: var(--grass-green);
    } //FUND
    &.etf {
        background-color: var(--grass-green);
    } //ETF
    &.zertifikat {
        background-color: var(--orange);
    } //CERT
    &.knock-out {
        // knock-out KO
        background-color: var(--pink-dark);
    }
    &.optionsschein {
        background-color: var(--pink-dark);
    } //WARR
    &.etc {
        background-color: var(--turquoise);
    } //ETC
    &.rohstoff {
        background-color: var(--turquoise);
    } //COMM
    &.wahrung {
        background-color: var(--gray-dark-asset);
    } //CROSS
    &.etn {
        background-color: var(--gray-dark-asset);
    } //ETN
    &.sonstige {
        background-color: var(--purple);
    } //OTHER


    //MEIN FINANZTREFF
    &.SHARE {
        background-color: var(--dark-blue);
    }
    &.INDEX {
        background-color: var(--yellow);
    }
    &.FUND {
        background-color: var(--grass-green);
    }
    &.ETF {
        background-color: var(--grass-green);
    }
    &.CERT {
        background-color: var(--orange);
    }
    &.KNOCK {
        background-color: var(--pink-dark);
    }
    &.WARR {
        background-color: var(--pink-dark);
    }
    &.ETC {
        background-color: var(--turquoise);
    }
    &.COMM {
        background-color: var(--turquoise);
    }
    &.CROSS {
        background-color: var(--gray-dark-asset);
    }
    &.ETN {
        background-color: var(--gray-dark-asset);
    }
    &.OTHER {
        background-color: var(--purple);
    } 
    &.BOND, &.FUT, &.MMR, &.REAL_ESTATE, &.MULTI, &.OPT, &.VWL {
        background-color: black;
    }

    ~ .asset-info {
        font-size: 12px;
        margin-left: 14px;
    }

    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
        //
    }
}

.asset-type-text-color {
    &.default {
        color: var(--dark-blue);
    }
    &.aktie, &.SHARE {
        color: var(--dark-blue);
    }
    &.index, &.INDEX {
        color: var(--yellow);
    }
    &.fonds, &.FUND {
        color: var(--grass-green);
    } //FUND
    &.etf, &.ETF {
        color: var(--grass-green);
    } //ETF
    &.zertifikat, &.CERT {
        color: var(--orange);
    } //CERT
    &.knock-out, &.KNOCK {
        // knock-out KO
        color: var(--pink-dark);
    }
    &.optionsschein, &.WARR {
        color: var(--pink-dark);
    } //WARR
    &.etc, &.ETC {
        color: var(--turquoise);
    } //ETC
    &.rohstoff, &.COMM {
        color: var(--turquoise);
    } //COMM
    &.wahrung, &.CROSS {
        color: var(--gray-dark-asset);
    } //CROSS
    &.etn, &.ETN {
        color: var(--gray-dark-asset);
    } //ETN
    &.sonstige, &.OTHER {
        color: var(--purple);
    } //OTHER
}

.copy-button {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
}
.btn.copy-button:hover,
.btn.copy-button:focus,
.btn.copy-button:active {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
}

.carousel.inner-carousel {
    padding-bottom: 22px;

    .carousel-controls {
        margin-bottom: 0;
    }
}

.timing-info-box {
    font-size: 11px;
    font-weight: bold;
    color: #ffffff;
    background-color: transparent;
    display: inline-block;
    line-height: 1;
    padding: 1px 4px 0;
    vertical-align: middle;
    border-radius: 0;
    padding: 0;
    min-width: 28px;
    top: -1px;
    position: relative;

    > span {
        text-align: center;
        display: inline-block;
        padding: 1px 6px 0;
    }
}

.view-selection {
    button:not(.input-button) {
        background-color: white;
        border-radius: 5px;
        padding: 7px 14px 7px;
        display: inline-flex;
        flex-flow: column;
        line-height: 1.2;

        &.active {
            &.bg-white {
                background-color: var(--blue) !important;
                color: white !important;
            }
        }

        + button,
        + div {
            margin-left: 16px;
        }

        span {
            font-size: 13px;

            &.view-type {
                font-size: 15px;
                font-weight: bold;
            }

            i {
                &.drop-arrow {
                    margin-left: 28px;
                    position: relative;
                    top: -2px;
                }
            }
        }
    }

    .dropdown-menu {
        margin-left: 0 !important;
        padding: 10px;
        border-color: var(--border-gray);
        border-width: 2px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.22);

        &.dropdown-search-filter {
            padding: 8px;
            margin-top: 6px;
            border-color: var(--kurs-grau);

            .drop-header {
                margin-bottom: 20px;
                padding-bottom: 5px;
                position: relative;
                font-weight: bold;
                font-size: 16px;
                border-bottom: 2px solid var(--border-gray);

                .drop-arrow-image {
                    position: absolute;
                    right: -7px;
                    top: -5px;
                    cursor: pointer;
                }
            }

            .common-input-row {
                padding-bottom: 15px;
                border-bottom: 3px solid var(--border-gray);

                > input {
                    border-radius: 0;
                    max-width: 50%;
                }

                .search-tags {
                    flex-grow: 1;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    > span {
                        + span {
                            margin-left: 8px;
                        }
                    }
                }
            }

            .search-results {
                overflow-y: auto;
                max-height: 100%;
                min-height: 350px;
                padding-top: 0;

                > .result-item {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 12px;
                    padding: 15px 0;
                    border-bottom: 1px solid var(--border-gray);

                    .name {
                        font-size: 18px;
                        font-weight: bold;
                        color: var(--blue);
                    }

                    .type {
                        font-weight: bold;
                        padding-left: 15px;
                        color: var(--blue);
                    }
                }
            }
        }

        .input-button {
            text-align: center;
            padding: 5px 6px;
            font-size: 13px;
            border-radius: 3px;
            margin-left: 8px;
            min-width: 70px;
        }

        .search-results {
            padding-top: 15px;
        }

        .dropdown-item {
            padding: 5px;
            border-top: 1px solid var(--border-gray);
        }
    }
}

.common-input-row {
    &.input-bg {
        input {
            background-color: var(--border-gray);
        }
    }

    input {
        border-radius: 3px;
        border-color: var(--border-gray);
        color: var(--main-text);
    }
}

.derivate-big-card {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    padding: 0 16px 0;
    margin-bottom: 30px;

    &.single-whole-width {
        .top {
            .asset-info {
                justify-content: flex-start;
            }
        }

        .slider-wrapper {
            padding-top: 16px;
            padding-bottom: 8px;

            .carousel {
                padding-bottom: 77px;

                .carousel-slide-control-wrapper {
                    opacity: 1;
                    color: $body-color;
                    font-weight: bold;

                    &:hover {
                        > span {
                            &.move-arrow {
                                opacity: 1;
                            }
                        }
                    }

                    > span {
                        opacity: 1;

                        &.move-arrow {
                            opacity: 0.5;
                            padding: 0 10px;
                        }
                    }
                }
            }
        }

        .inner-row-wrapper {
            padding-top: 16px;
            padding-bottom: 14px;
        }

        .product-wrapper {
            padding: 10px 16px;
            border: 1px solid var(--border-gray);
            text-align: center;
            height: 100%;

            .title {
                font-size: 18px;
                font-weight: bold;
            }

            .top-section {
                border-bottom: 1px solid var(--border-gray);
                padding-bottom: 5px;
                margin-bottom: 15px;
            }

            .middle-section {
                border-bottom: 1px solid var(--border-gray);
                line-height: 1.1;

                > div {
                    margin-bottom: 12px;
                }

                .wkn {
                    font-size: 12px;
                    font-weight: bold;
                    color: var(--blue);
                }
            }

            .bottom-section {
                padding-top: 10px;
                padding-bottom: 12px;

                .title {
                    padding-bottom: 5px;
                }
            }
        }
    }

    &.tags-card {
        padding: 33px 42px 45px;
        height: calc(100% - 30px);

        .data-wrapper {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;

            .title {
                font-size: 24px;
                font-weight: bold;
                text-align: center;
                padding-bottom: 12px;
            }

            .tags-wrap {
                text-align: center;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin-left: -8px;

                > span {
                    background-color: var(--border-gray);
                    font-size: 13px;
                    padding: 2px 8px;
                    margin-bottom: 16px;

                    + span {
                        margin-left: 16px;
                    }

                    a {
                        text-decoration: none;
                        color: $body-color;

                        &:hover {
                            color: var(--blue);
                        }
                    }
                }
            }

            .button-row {
                padding-top: 40px;
            }
        }
    }

    .top {
        background-color: $dark-background;
        margin-left: -16px;
        margin-right: -16px;
        padding: 10px 16px;
        line-height: 1.2;
        display: flex;
        align-items: center;

        .asset-name {
            @media screen and (min-width:1100px) and (max-width:1279px){
                font-size: 12px !important;    
            }
            font-size: 18px;
            color: white;
            margin-left: 12px;
            position: relative;
            line-height: 1;
            cursor: pointer;

            span {
                &.svg-icon {
                    position: absolute;
                    top: -5px;

                    + span,
                    + a {
                        padding-left: 28px;
                    }
                }
            }
        }

        .asset-info {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
            color: white;
            font-size: 14px;

            > span {
                + span {
                    padding-left: 7px;
                }
            }
        }
    }

    .data-wrapper {
        display: flex;
        justify-content: space-between;

        &.vertical {
            display: block;
            border: 1px solid var(--border-gray);

            .card-type {
                font-size: 24px;
                font-family: "Roboto Slab", sans-serif;
                text-align: center;
                padding-top: 15px;
                padding-bottom: 20px;
            }

            .left-side {
                padding: 0 22px;

                .info-row {
                    border: none;
                }

                .bottom-info {
                    text-align: center;
                }
            }

            .weitere {
                width: 100%;
                padding: 15px 85px;

                .info-row {
                    padding-bottom: 7px;
                }
            }
        }

        .left-side {
            flex-grow: 1;
            padding-right: 10px;

            .info-row {
                padding-top: 13px;
                padding-bottom: 15px;
                border-bottom: 1px solid var(--border-gray);
            }

            .tag-wrap {
                padding-right: 12px;
            }

            .call-put-tag {
                font-size: 14px;
                font-weight: bold;
                color: white;
                padding: 2px 11px 0;
                min-width: 46px;
                display: inline-block;
                line-height: 1.2;

                &.call {
                    background-color: var(--green);
                }

                &.put {
                    background-color: var(--pink);
                }
            }

            .data-info {
                font-size: 12px;
                line-height: 1.2;
                flex-grow: 1;

                span {
                    display: inline-block;
                }

                @media screen and (min-width:1100px) and (max-width:1279px) {
                    font-size: 10px;
                    .left-side-info {
                        min-width: 115px !important;
                    }
                }
                .left-side-info {
                    min-width: 175px;
                }
            }

            .add-wrap {
                flex-grow: 1;
                text-align: right;
            }

            .value {
                font-size: 24px;
                font-weight: bold;
                line-height: 1;
                padding-bottom: 12px;
                display: flex;
            }

            .bottom-info {
                font-size: 12px;
                color: var(--kurs-grau);
                padding: 10px 0;
                text-align: right;
            }
        }

        .weitere {
            width: auto;
            background-color: rgba(0, 0, 0, 0.03);
            margin-right: -16px;
            padding: 10px 14px;
            margin-top:2px;
            .title {
                font-size: 18px;
                font-weight: bold;
                text-align: center;
                padding-bottom: 8px;
                margin-top:-6px;
            }

            .info-row {
                font-size: 14px;
                font-weight: bold;
                line-height: 1;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 12px;

                > span {
                    min-width: 46px;
                    display: inline-block;
                    text-align: center;
                }

                .call-put-tag {
                    font-size: 14px;
                    font-weight: bold;
                    color: white;
                    padding: 2px 11px 0;
                    min-width: 46px;
                    display: inline-block;
                    line-height: 1.2;

                    &.call {
                        background-color: var(--green);
                    }

                    &.put {
                        background-color: var(--pink);
                    }
                }

                .call-put-value {
                    font-size: 16px;
                    color: var(--blue);
                }
            }

            > button {
                padding: 5px 6px;
                // margin-top: 10px;
            }

            .bottom-row {
                line-height: 1.3;
                text-align: center;
                padding-top: 17px;
                margin-top: 10px;
                border-top: 1px solid #e4e4e4;

                button {
                    padding: 5px 6px;
                    margin-top: 10px;
                }
            }
        }

        .accordion {
            margin-left: -8px;
            margin-right: -8px;

            .card-body {
                text-align: center;
                padding-top: 16px;

                .middle-section {
                    .wkn {
                        color: var(--blue);
                        font-weight: bold;
                        font-size: 16px;
                        padding-bottom: 12px;
                    }

                    .info-row {
                        > span {
                            + span {
                                padding-left: 30px;
                            }
                        }
                    }
                }

                .bottom-section {
                    margin-top: 14px;
                    padding-top: 14px;
                    padding-bottom: 14px;
                    border-top: 1px solid var(--border-gray);

                    .title {
                        font-size: 18px;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    @media screen and (max-width: map-get($grid-breakpoints, xl) - 1) {
        padding: 0 8px 0;
        margin-bottom: 16px;

        &.single-whole-width {
            .top {
                .asset-info {
                    display: flex;
                }
            }

            .product-wrapper {
                margin-bottom: 16px;
                height: calc(100% - 16px);
            }
        }

        &.tags-card {
            padding: 20px 20px 30px;
            height: calc(100% - 16px);

            .data-wrapper {
                .title {
                    line-height: 1.1;
                    padding-bottom: 35px;
                }

                .tags-wrap {
                    > span {
                        margin-bottom: 9px;
                    }
                }

                .button-row {
                    flex-direction: column;
                    padding-top: 35px;

                    span {
                        padding-right: 0 !important;
                        padding-bottom: 10px;
                    }
                }
            }
        }

        .top {
            margin-left: -8px;
            margin-right: -8px;
            padding: 8px;

            .asset-info {
                display: none;
            }
        }

        .data-wrapper {
            display: block;

            &.vertical {
                .card-type {
                    padding: 0;
                }

                .left-side {
                    .tag-wrap {
                        padding-bottom: 10px;
                    }

                    .value {
                        font-size: 20px;
                    }

                    .info-row {
                        flex-direction: column;
                    }
                }

                .weitere {
                    width: auto;
                    padding: 10px 15px;
                    margin: 0;

                    .bottom-row {
                        text-align: center;
                        border: 0;
                        border-top: 1px solid #e4e4e4;
                        margin: 0;
                        padding: 0;
                        margin-top: 10px;
                        padding-top: 15px;
                    }
                }
            }

            .left-side {
                padding: 0;

                .value {
                    padding-bottom: 0;
                    font-size: 20px;
                }

                .data-info {
                    .left-side-info {
                        + span {
                            + span {
                                display: none;
                            }
                        }
                    }
                }

                .bottom-info {
                    text-align: center;
                }
            }

            .weitere {
                padding: 10px;
                width: auto;
                margin-left: -8px;
                margin-right: -8px;

                .info-row {
                    padding-bottom: 6px;
                }

                .bottom-row {
                    border: 0;
                    border-left: 1px solid #e4e4e4;
                    margin-top: 0;
                    margin-left: 15px;
                    padding-left: 22px;
                    text-align: left;
                    padding-top: 0;

                    .werbung {
                        font-size: 10px;
                        text-transform: uppercase;
                        color: var(--kurs-grau);
                        padding-top: 12px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
        &.single-whole-width {
            .top {
                .asset-info {
                    display: none;
                }
            }
        }

        .carousel {
            .carousel-inner {
                .tags-card {
                    padding: 20px 20px 36px;
                    // height: calc(100% - 16px);

                    .data-wrapper {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        height: 100%;

                        .title {
                            font-size: 24px;
                            font-weight: bold;
                            text-align: center;
                            line-height: 1.1;
                            padding-bottom: 35px;
                        }

                        .tags-wrap {
                            text-align: center;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            margin-left: -8px;

                            > span {
                                background-color: var(--border-gray);
                                font-size: 13px;
                                padding: 2px 8px;
                                margin-bottom: 9px;

                                + span {
                                    margin-left: 16px;
                                }

                                a {
                                    text-decoration: none;
                                    color: $body-color;

                                    &:hover {
                                        color: var(--blue);
                                    }
                                }
                            }
                        }

                        .button-row {
                            flex-direction: column;
                            padding-top: 35px;

                            span {
                                padding-right: 0 !important;
                                padding-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }

        .top {
            .asset-info {
                display: none;
            }
        }
    }
}

.filter-section-modal {
    height: 100%;
    .filter-header {
        margin-bottom: 20px;
        padding-bottom: 5px;
    }

    .common-input-row {
        padding-bottom: 15px;
        border-bottom: 2px solid var(--border-gray);

        > input {
            border-radius: 0;
            max-width: 50%;
        }

        .search-tags {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            > span {
                + span {
                    margin-left: 8px;
                }
            }
        }
    }

    .search-results {
        overflow-y: auto;
        height: 100%;
        align-content: start;
        min-height: 350px;

        > .result-item {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
            padding: 15px 0;
            border-bottom: 1px solid var(--border-gray);

            .name {
                font-size: 18px;
                font-weight: bold;
                color: var(--blue);
            }

            .type {
                font-weight: bold;
                padding-left: 15px;
                color: var(--blue);
            }
        }
    }

    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
        .common-input-row {
            flex-direction: column;
            max-width: 100%;

            > input {
                max-width: 100%;
            }

            .search-tags {
                padding-top: 10px;
                justify-content: flex-start;
            }
        }

        .search-results {
            > .result-item {
                display: block;
                padding: 8px 0;

                .name {
                    font-size: 16px;
                }

                .type {
                    padding: 0;
                    padding-right: 15px;
                }
            }
        }
    }
}

.button-active-undeline {
    border-bottom: #326EAC 2.3px solid !important;
}

// Mein Finanztreff BG
.bg-SHARE {
background-color: var(--dark-blue);
}
.bg-INDEX {
    background-color: var(--yellow);
}
.bg-FUND {
    background-color: var(--grass-green);
}
.bg-ETF {
    background-color: var(--grass-green);
}
.bg-CERT {
    background-color: var(--orange);
}
.bg-KNOCK {
    background-color: var(--pink-dark);
}
.bg-WARR {
    background-color: var(--pink-dark);
}
.bg-ETC {
    background-color: var(--turquoise);
}
.bg-COMM {
    background-color: var(--turquoise);
}
.bg-CROSS {
    background-color: var(--gray-dark);
}
.bg-ETN {
    background-color: var(--gray-dark);
}
.bg-OTHER {
    background-color: var(--purple);
}
.bg-BOND {
    background-color:#855C7F;
}

.bg-FUT, .bg-REAL_ESTATE, .bg-MULTI , .bg-OPT , .bg-MMR {
    background-color: black;
}
