@media screen and (min-width:1100px) and (max-width:1279px) {
    .bonds-search-container{
        max-width: 1280px !important;
    }
    .bonds-search-heading{
        font-size: 24px !important;
    }
    
}
@media screen and (min-width:1280px) {
    .bonds-search-container{
        max-width: 1280px !important;
    }
    .bonds-search-heading{
        font-size: 24px !important;
    }
    
}
@media screen and (max-width:1099px){
    .bonds-search-container{
        max-width: 768px !important;
    }
    
}
.tagcloud{
    text-decoration: none;
    border: none;
    font-weight: bold;
    color: #326EAC;
    padding: 0;
    font-size: 16px;
    margin-right: 16px;
    border-radius: 0 !important;
    margin-top: 4px;
    &.btn-primary:hover{
        color: #326EAC !important
    }
    &.active{
        border-bottom: 2px solid;
        color: #326EAC !important;
    }
}
.fund-banner-search {
    max-height: 520px;
    overflow-y: scroll; /* Add the ability to scroll */
  }
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  .fund-banner-search::-webkit-scrollbar {
      display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .fund-banner-search {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

#anleihen-suche .asset-type-tag{
    background-color: #855C7F !important;
}