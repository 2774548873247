.mobile-modal-filter {
    @media screen and (max-width: map-get($grid-breakpoints, md)) {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: auto;
        padding: 1em;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: fit-content;
        background-color: white;
        margin: 0 !important;
        z-index: 1300;

        &.show {
            display: block !important;
        }
        .dropdown-filter {
            width: 100%;
            margin-left: 0 !important;
            margin-bottom: 1em;

            button {
                width: 100%;
                text-align: left;
            }
        }

        &.overlay {
            position: fixed;
            left: 0; top: 0; right: 0; bottom: 0;
            width: 100vw;
            height: 100vh;
            background-color: #000;
            opacity: 0.39;
            z-index: 1200;
        }

    }
    .index-mobile-filter-title {
        display: none;
        @media screen and (max-width: map-get($grid-breakpoints, md)) {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.5em;
        }
    }
}
