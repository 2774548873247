@import "../mixins.scss";
@import "../variables.scss";

.modal{
    &#bilanzModal {
        @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
            table {
                tr {
                    th {
                        &:nth-child(3) {
                            display: none;
                        }
                    }

                    td {
                        &:first-child {
                            padding-right: 0;
                            max-width: 168px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        &:nth-child(3) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}