@import "src/styles/theme";

.sky-container-col{
    padding-left: unset !important;
    padding-right: unset !important;
    width: 120px !important;
    background: white !important;
    flex-basis: 0 !important;
    flex-grow: 1 !important;
    overflow: hidden !important;
}

.main-skyscraper-container{
    @media screen and (min-width: 1280px){
        max-width: 1440px !important;
    }
    @media screen and (min-width: 767px) and (max-width: 1280px){
        max-width: 928px !important;
    }

}
.skyscraper-container{
        @media screen and (min-width: 1280px){
            padding-right: unset !important;
            padding-left: unset !important;
            z-index: 99 !important;
            box-shadow: 0 0 10px 0 #00000038;
        }
        @media screen and (min-width: 767px) and (max-width: 1280px){
            padding-right: unset !important;
            padding-left: unset !important;
            z-index: 99 !important;
            box-shadow: 0 0 10px 0 #00000038;
        }
}
.remove-margin-rows{
    margin-left: unset !important;
    margin-right: unset !important
}
.remove-main-wrapper{
    box-shadow: none !important;
}
.modal-dialog-sky-placement{
    @media screen and (min-width: 821px){
        padding-right: 105px !important;
    }
}
.modal-dialog-sky-placement-date{
    @media screen and (min-width: 821px){
        left: 0;
        right: 105px;
    }
}
#show-alert-text{
    background-color: #FF4D7D ;
    padding: 6px 16px 6px 20px;
}
#show-alert-text p{
    margin-bottom: 0px !important;
}
@media screen and (min-width:699px) and (max-width:1099px) {
    #show-alert-text{
        background-color: #FF4D7D ;
        margin-top: 10px !important;
        padding: 6px 16px 6px 16px !important;
    }
}
@media screen and (max-width:698px) {
    #show-alert-text{
        background-color: #FF4D7D ;
        margin-top: 10px !important;
        padding: 6px 8px 6px 8px !important;
    }
}
#search-results-modal .search-results{
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
#search-results-modal .search-results::-webkit-scrollbar {
    @media screen and (min-width: 821px){
        display: none;
    }
}
@media screen and (max-width: map-get($grid-breakpoints, xl)) {
    .advertisment-container{
        max-width:map-get($container-max-widths, md) + 120px !important;
    }
    .skyad-placement{
        right: 88px !important;
    }
}
@media screen and (min-width: map-get($grid-breakpoints, xl)) {
    .advertisment-container{
        max-width:map-get($container-max-widths, xl) + 300px !important;
    }
    .skyad-placement{
        right: 88px !important;
        max-width: 1280px !important;
    }
    .responsive-lg{
        max-width: 1280px !important;
    }
}
@media screen and (max-width:1099px) {
    .responsive-lg{
        max-width: 768px !important;
    }
    
}
@media screen and (min-width: map-get($grid-breakpoints, xl)) and (max-width: map-get($grid-breakpoints, xl) + 60px) {
    .sky-container-col{
        display: none !important;
    }
    .skyad-placement{
        right: 0 !important;
    }
}
@media screen and (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, md) + 60px) {
    .sky-container-col{
        display: none !important;
    }
    .skyad-placement{
        right: 0 !important;
    }
}
