@import "../mixins.scss";
@import "../variables.scss";

.modal-dialog {
    &.main-search-modal {
        .modal-content {
            background-color: white;

            .modal-header {
                box-shadow: none;
                border: none;

                button {
                    &.close {
                        font-size: 15px;
                    }
                }
            }

            .modal-body {
                padding-bottom: 16px;

                form {
                    justify-content: center;
                    padding-top: 16px;
                    padding-bottom: 40px;

                    .input-row {
                        display: flex;

                        input {
                            &.form-control {
                                &.main-search-input {
                                    font-size: 18px;
                                    font-weight: bold;
                                    min-width: 450px;
                                    padding: 6px 8px;
                                    line-height: 1;
                                    height: auto;

                                    &::placeholder {
                                        /* Chrome, Firefox, Opera, Safari 10.1+ */
                                        color: $body-color;
                                        font-weight: normal;
                                        opacity: 1; /* Firefox */
                                    }

                                    &:-ms-input-placeholder {
                                        /* Internet Explorer 10-11 */
                                        color: $body-color;
                                        font-weight: normal;
                                    }

                                    &::-ms-input-placeholder {
                                        /* Microsoft Edge */
                                        color: $body-color;
                                        font-weight: normal;
                                    }
                                }
                            }
                        }

                        button {
                            font-size: 18px;
                            min-width: 140px;
                        }
                    }
                }

                .most-searched {
                    padding: 0 16px;

                    h5 {
                        font-weight: bold;
                    }

                    .search-text-item {
                        background-color: var(--border-gray);
                        padding: 0 8px;
                        font-size: 13px;
                        margin-right: 16px;
                        margin-bottom: 10px;

                        a {
                            color: $body-color;
                            text-decoration: none;
                        }
                    }
                }

                .search-content {
                    padding: 0 16px 50px;

                    > .row {
                        > .col {
                            &:first-child {
                                .result-wrapper {
                                    padding-right: 15px;
                                }
                            }
                        }
                    }

                    .top {
                        h4 {
                            font-size: 24px;
                            font-family: "Roboto Slab", sans-serif;
                            border-bottom: 3px solid var(--border-gray);
                            margin-bottom: 15px;
                            padding-bottom: 10px;
                        }

                        .filter-row {
                            padding-bottom: 6px;
                            flex-wrap: wrap;

                            .filter-selector {
                                margin-right: 8px;
                                padding-bottom: 4px;
                                border-bottom: 2px solid transparent;
                                margin-bottom: 8px;

                                &.active {
                                    border-bottom-color: var(--gray-dark);
                                }

                                .asset-type-tag {
                                    font-size: 12px;
                                    white-space: nowrap;
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    .results {
                        .table-like-row-wrapper {
                            &.last-with-border {
                                > .result-row {
                                    &:last-child {
                                        border-bottom: 1px solid var(--border-gray);
                                    }
                                }
                            }
                        }

                        .result-row {
                            padding: 18px 0;
                            border-top: 1px solid var(--border-gray);
                            line-height: 1;

                            .asset-name {
                                a {
                                    font-size: 18px;
                                    font-weight: bold;
                                    cursor: pointer;
                                }
                            }

                            .wkn-type-info {
                                font-size: 12px;

                                > span {
                                    display: inline-block;
                                }

                                .type-info {
                                    min-width: 100px;
                                    text-align: right;
                                    font-weight: bold;
                                    text-transform: uppercase;
                                }
                            }
                        }

                        .small-news-row {
                            .news-wrapper {
                                h4 {
                                    font-size: 18px;
                                    font-weight: bold;
                                }

                                .asset-name {
                                    font-weight: bold;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media screen and (max-width: map-get($grid-breakpoints, xl) - 1) {
            .modal-content {
                .modal-body {
                    padding-left: 16px;
                    padding-right: 16px;
                    padding-bottom: 8px;

                    form {
                        padding-top: 32px;

                        .input-row {
                            flex-grow: 1;

                            > .form-group {
                                flex-grow: 1;
                            }

                            input {
                                &.form-control {
                                    &.main-search-input {
                                        flex-grow: 1;
                                    }
                                }
                            }
                        }
                    }

                    .most-searched {
                        padding: 0;
                    }

                    .search-content {
                        padding-left: 0;
                        padding-right: 0;
                        padding-bottom: 0;

                        .result-wrapper {
                            padding: 0 0 50px !important;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: map-get($grid-breakpoints, lg) - 1) {
            .modal-content {
                .modal-body {
                    padding: 8px;

                    form {
                        padding-top: 18px;

                        .input-row {
                            input {
                                &.form-control {
                                    &.main-search-input {
                                        min-width: 0;
                                        font-size: 15px;
                                        padding-top: 5px;
                                        padding-bottom: 5px;
                                    }
                                }
                            }

                            button {
                                font-size: 14px;
                                min-width: 0;
                                margin-left: 8px;
                                padding-top: 5px;
                                padding-bottom: 5px;
                            }
                        }
                    }

                    .search-content {
                        .results {
                            .result-row {
                                padding: 12px 0;
                                flex-direction: column;
                                align-items: flex-start !important;

                                .asset-name {
                                    cursor: pointer;
                                    a {
                                        font-size: 16px;
                                        cursor: pointer;
                                    }
                                }

                                .wkn-type-info {
                                    padding-top: 5px;
                                    display: flex;
                                    flex-direction: row-reverse;

                                    .type-info {
                                        min-width: 90px;
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.bottom-with-hight{
    .modal-content{
        height: 90vh !important;
    }
}