.sub-navigation {
    &.nav-wrapper {
        button {
            border: none;
            @each $direction in top left right {
                border-#{$direction}: none;
            }
            border-bottom: 2px solid transparent;
            border-radius: 0;
            &:hover {
                text-decoration: none;
                border-bottom-color: var(--blue);
            }

            &.active {
                border-bottom-color: var(--blue);
            }
        }
    }
}
@media screen and (min-width:1100px) and (max-width:1279px) {
  .main-section-container{
    max-width: 1280px !important;
  }
  
}
@media screen and (min-width:1280px){
  .main-section-container{
    max-width: 1280px !important;
  }
  
}
@media screen and (max-width:1099px) {
  .main-section-container{
    max-width: 768px !important;
  }
  
}
