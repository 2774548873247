@import "../mixins.scss";
@import "../variables.scss";

form {
    &.modal-form {
        &.input-bg {
            .form-control {
                background-color: var(--border-gray);
            }

            .input-group-text {
                background-color: #F1F1F1;
                border-color: #F1F1F1;
                color: black;
            }

        }

        .form-control {
            border-radius: 3px;
            border-width: 2px;
            border-color: var(--border-gray);
            color: var(--main-text);
        }

        input {
            &.form-control {
                font-size: 15px;

                &:required {
                    &:placeholder-shown {
                        border-width: 2px;
                        border-color: var(--border-cyclamen);
                    }

                    &.no-selection {
                        border-color: var(--border-cyclamen);
                    }
                }

                &[type="button"] {
                    text-align: left;
                    font-size: 15px;

                    &.required {
                        &.no-selection {
                            border-color: var(--border-cyclamen);
                        }
                    }
                }

                &.form-control-sm {
                    + .floating-label {
                        height: calc(1.5em + 0.5rem + 2px);
                        padding: 0.25rem 12px;
                        padding-right: 0;
                    }
                }

                + .floating-label {
                    position: absolute;
                    top: 0;
                    color: var(--kurs-grau);
                    line-height: 1.7;
                    font-weight: normal;
                }

                + i {
                    &.drop-arrow {
                        transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                        bottom: 13px;
                        top: auto;

                        &.right-float-arrow {
                            position: absolute;
                            right: 10px;
                        }
                    }
                }
            }

            &.search-input-with-icon {
                padding-right: 35px;
                // background-image: url(/static/img/svg/icon_search_blue.svg);
                background-repeat: no-repeat;
                background-size: 21px;
                background-position: center right 5px;
            }

            &.date-input-with-icon {
                padding-right: 40px;
                // background-image: url(/static/img/svg/icon_calender_dark.svg);
                background-repeat: no-repeat;
                background-size: 30px;
                background-position: center right 3px;
            }
        }

        .textarea-wrapper {
            position: relative;

            textarea {
                + div[data-toggle="modal"] {
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 5;
                    background-color: transparent;
                }
            }
        }

        textarea {
            resize: none;
            border-radius: 3px;
            border-color: var(--border-gray);
            color: var(--main-text);
            background-color: var(--border-gray);
            line-height: 1.3;
            padding: 8px;
        }

        .dropdown {
            &.dropdown-select {
                &.show {
                    input {
                        &.form-control {
                            &.required {
                                &.no-selection {
                                    border-color: transparent;
                                }
                            }
                        }
                    }
                }

                .dropdown-menu {
                    margin-top: -2px;
                    margin-left: 2px;
                    border-width: 2px;
                    border-color: var(--border-gray);
                    width: calc(100% - 4px);
                    padding-left: 8px;
                    padding-right: 8px;
                    padding-top: 0;

                    .dropdown-item {
                        padding: 5px 0;
                        font-weight: bold;
                        border-bottom: 1px solid var(--border-gray);

                        &.add-new-item {
                            font-size: 14px;
                            font-weight: normal;
                            padding-top: 22px;
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }

    .form-group {
        > label {
            font-size: inherit;
        }

        .with-floating-label {
            position: relative;
        }
    }
}

[inline-date-picker-container],
[data-section="inline-date-picker-container"] {
    min-height: 240px;

    table {
        font-size: 15px;

        th {
            padding: 0 !important;

            &.dow {
                text-transform: uppercase;
                font-weight: normal;
                color: #bababa;
            }
        }

        td {
            &.day {
                padding: 0 !important;
                border-radius: 50% !important;

                &.active {
                    background-color: var(--primary) !important;
                    border-color: var(--primary) !important;
                }
            }
        }
    }
}
