.ads-responsive-class{
    @media screen and (max-width: 767px){
      display:none !important;
    }
}
.ads-responsive-class-footer{
  padding: 32px 0;
}
.remove-margin-footer{
  margin-top: 0px !important;
}
.footer-legend-text{
  font-size: 13px;
  font: normal normal normal 13px/18px Roboto;
  color: #383838;
 opacity: 1;
}
.footer-legend-text-container{
  margin-bottom: -8px;
  margin-top: -14px;
  @media screen and (min-width:699px) and (max-width:1099px){
    margin-top: -12px;
    margin-left: 20px;
    
  }
  @media screen and (min-width:1100px) {
    margin-top:-12px;
    margin-left: 20px;
}
  }
.legend-container{
  @media screen and (min-width:699px) {
    margin-bottom: -5px;
 }
}
.blog-main-container{
  margin-left: -18px;
}
.blog-text-container{
  @media screen and (max-width:698px) {
    margin-top: 14px;
    margin-left: 1px !important;
    
  }
}
.footer-delay-legend{
  padding-right: 6px;
  padding-left: 6px;
}
.legend-name{
line-height: 13px !important;
text-align: center;
font: normal normal bold 11px Roboto;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
}
.rt-container{
@media screen and (min-width:699px){
  margin-bottom: -3px;
  }
   }
