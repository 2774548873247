.calender-card-wrapper {
  .calender {

    &-card {
      width: 126px;
      height: 110px;
      border-radius: 0 0 4px 4px;
      background: linear-gradient(137deg, #F1F1F1 60%, #BBBBBB 100%) !important;

      &-active {
        color: #ffffff;
        background: linear-gradient(137deg, #5B9DE1 60%, #3476BA 100%) !important;
      }
    }

    &-card:hover {
      color: #383838;
      background: linear-gradient(137deg, #f8f8f8 60%, #d4d4d4 100%);
      cursor: pointer;
    }


    &_border {
      width: 100%;
      margin-top: -2px;
      border-top: dotted 5px;
      border-color: #383838;
    }

    &_day_name {
      font: normal normal normal 13px/22px Roboto;

    }

    &_date {
      font: normal normal bold 34px/22px Roboto;
    }

    &_bottom_content {
      font: normal normal normal 18px/22px Roboto;
    }
    @media screen and (min-width:1100px) and (max-width:1279px){
    &-card {
      width: 100px !important;
    }
    }
  }

}

/* Design for tablet  */
.t-calender {
  &-card {
    width: 94px !important;
    height: 94px;
    border-radius: 0 0 4px 4px;
    background: linear-gradient(137deg, #F1F1F1 60%, #BBBBBB 100%);

    &-wide {
      width: 124px !important;
    }

    .calender_day_name {
      font-size: 15px;
    }

    .calender_date {
      font-size: 30px;
    }

    .calender_bottom_content {
      font-size: 15px;
    }


  }

}

/* Design for mobile */
.m-calender {
  &-card {
    width: 62px;
    height: 74px;
    background: linear-gradient(137deg, #F1F1F1 60%, #BBBBBB 100%);
    border-radius: 0 0 5px 5px;

    .calender_day_name {
      font-size: 13px;
    }

    .calender_date {
      font-size: 30px;
    }

    .calender_bottom_content {
      font-size: 13px;
    }

  }
}

