@import "../mixins.scss";
@import "../variables.scss";

.analysen-kurs {
    position: relative;

    .left-side {
        .small-title {
            font-size: 15px;
            margin-bottom: 10px;
        }

        .info-box {
            padding: 13px 40px;

            .value-row {
                font-size: 29px;
                line-height: 1;
            }
            .percent-row {
                font-size: 20px;
                margin-top: 6px;
                line-height: 1;
            }
            .buy-sell-rating {
                width: 100%;
                max-width: 40%;
                font-size: 13px;
                line-height: 1;

                .buy-or-sell {
                    font-size: 24px;
                    text-transform: uppercase;
                    margin-top: 3px;
                }
            }
        }
    }

    .info-numbers {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 30px;

        > span {
            display: inline-block;
            margin: 0 15px;
            font-size: 16px;
            font-weight: bold;
            border-bottom: 4px solid;
            line-height: 1;

            &.kaufen {
                border-color: var(--green);
            }
            &.halten {
                border-color: var(--yellow);
            }
            &.verkaufen {
                border-color: var(--pink);
            }
        }
    }

    .vertical-float-border {
        background: transparent linear-gradient(0deg, #00000000 0%, #00000033 51%, #00000000 100%) 0% 0% no-repeat
            padding-box;
        position: absolute;
        top: 0;
        right: 50%;
        width: 2px;
        height: 100%;
        z-index: 1;
    }

    @media screen and (max-width: map-get($grid-breakpoints, xl) - 1) {
        .left-side {
            .small-title {
                text-align: left;
            }

            .info-box {
                padding: 6px 13px;

                .buy-sell-rating {
                    border-width: 1px;
                    border-style: solid;
                    padding: 7px;
                    max-width: 43%;
                }
            }
        }
    }
}