.select-instrument-trigger {
  .btn {
    background-color: #F1F1F1;
    border-color: #F1F1F1;
    color: black;
    padding: 6.75px 8px;
    :hover {
      text-decoration: none;
    }
  }

}
