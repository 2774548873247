@import "../../../styles/variables.scss";
@import "../../../styles/theme.scss";

@each $color,
$value in $stock-move-colors {
  .bg-stock-#{$color} {
    background-color: $value !important;
  }
}

#devisen-table-section {
  .active-cell-devisen{
    background-color: #383838 !important;
    color: #FFFFFF !important;
  }
  .scrollable-table {
    width: 100%;
    height: 100%;
    overflow: auto;
    overflow-y: hidden;
  }

  table {
    width: 100% !important;
    border-spacing: 2px 2px;
    border-style: hidden !important;

    thead>tr {
      position: sticky;
      left: 0;
      top: 0;
      z-index: 25;
      height: auto;
      display: block;

      th:first-child {
        background-color: #FFFFFF !important;
        text-align: center;
      }
    }

    tbody {
      display: block;
    }

    tr {
      // height: 1.375rem;
      cursor: pointer;

      :nth-child(even) {
        background-color: #F2F2F2;
      }

      :nth-child(odd) {
        background-color: #F2F2F2;
      }

      th:first-child {
        text-align: center;
        font-weight: bold;
        position: sticky;
        left: 0px;
        z-index: 40;
        background-color: #FFFFFF !important;
      }

      :last-child {
        td {
          border-bottom: 0;
        }
      }

      &.sticky {
        overflow-y: hidden;
        overflow-x: auto;

        .header {
          top: 0;
          box-shadow: 0px 3px 3px #ccc;
        }

        .body {
          position: relative;
          z-index: 0;
        }
      }
    }

    th,
    td {
      width: 6.65rem;
      margin: 0;
      padding: 0.4rem 0.2rem !important;
      // padding: 0.5rem; #F2F2F2
      border: 1px solid #F2F2F2 !important;
    }

    th {
      font-size: 1rem;
      border: none !important;
      cursor: default;

      :first-child {
        background-color: #FFFFFF !important;
      }

      :not(:first-child) {
        text-align: center;
        font-size: 1rem;
      }
    }
  }

  .header-cell-styling {
    background-color: #FFFFFF !important;
    margin: 2px !important;
  }

  .cell-styling {
    margin: 2px !important;
  }

  tbody tr:nth-child(1) td:nth-child(2),
  tbody tr:nth-child(2) td:nth-child(3),
  tbody tr:nth-child(3) td:nth-child(4),
  tbody tr:nth-child(4) td:nth-child(5),
  tbody tr:nth-child(5) td:nth-child(6),
  tbody tr:nth-child(6) td:nth-child(7),
  tbody tr:nth-child(7) td:nth-child(8),
  tbody tr:nth-child(8) td:nth-child(9),
  tbody tr:nth-child(9) td:nth-child(10),
  tbody tr:nth-child(10) td:nth-child(11) {
    background-color: #FFFFFF !important;
    pointer-events: none !important;
    cursor: default !important;
    :hover{
      cursor: default !important;
    }
  }

  .width-rows {
    width: 100% !important;
  }

  @media only screen and (max-width: 1280px) {
    table {

      th,
      td {
        width: 3.75rem !important;
        font-size: 13px !important;
      }
    }

    .table-flags-style {
      width: 17px !important;
      height: auto !important;
      max-height: 15px !important;
      border-radius: 0.1rem !important;
      margin-right: 10px !important;
      box-shadow: 1px 1px 2px 1px #00000029 !important;
    }
  }

  @media only screen and (min-width: 1281px) {
    table {

      th,
      td {
        width: 6.65rem !important;
        font-size: 0.96rem !important;
      }
    }

    .table-flags-style {
      width: 30px !important;
      height: auto !important;
      max-height: 20px !important;
      border-radius: 0.1rem !important;
      margin-right: 10px !important;
      box-shadow: 1px 1px 2px 1px #00000029 !important;
    }
  }

  @media only screen and (max-width: 767px) {
    table {

      th,
      td {
        width: 4.1rem !important;
        font-size: 13px !important;
      }
    }

    table tbody {
      padding-right: 8.5rem !important;
    }

    .scrollable-table {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    .scrollable-table::-webkit-scrollbar {
      display: none;
    }

    .table-flags-style {
      width: 20px !important;
      height: auto !important;
      max-height: 13.33px !important;
      border-radius: 0.1rem !important;
      margin-right: 5px !important;
      box-shadow: 1px 1px 2px 1px #00000029 !important;
    }
  }
}