@import 'src/styles/theme';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";

.page-container {

  .termine-page {

    &-header-content {
      padding: 0px 16px;

      h1 {
        font: normal normal bold 24px/32px Roboto Slab;
      }

      &-card {
        border-radius: 4px;
        background: linear-gradient(137deg, #f8f8f8 60%, #d4d4d4 100%);
        width: 288px !important;
        height: 110px;

        .card-heading {
          font: normal normal normal 13px/22px Roboto;
        }

        .card-value {
          font-size: 18px;
        }

        &-date {
          min-width: 114px;

          &-label {
            font-size: 13px;
          }
        }
      }

      &_date {
        font: normal normal bold 18px/36px Roboto;
      }


    }

    &-calender-card {
      min-width: 667px;
    }

    &-calender-card-description {
      min-width: 261px;
      margin-left: 17px;
    }

  @media screen and (min-width:1100px) and (max-width:1279px) {
    &-calender-card {
      min-width: 540px !important;
    }
    &-calender-card-description {
      min-width: 225px !important;
    }
  }
  @media screen and (min-width:699px) and (max-width:1099px) {
    &-calender-card {
      min-width: 420px !important;
    }
    &-calender-card-description {
      min-width: 225px !important;
    }
  }



    &-section-heading {
      font: normal normal bold 18px/24px Roboto Slab;
    }

    &-filter-button-section {
      width: 662px;

      h2 {
        font: normal normal bold 14px/19px Roboto;
        align-self: center;
      }
    }

    @media screen and (min-width:699px) and (max-width:1099px) {
      &-filter-button-section {
        width: 640px !important;
      }
    }

    &-button {
      width: 73px;
      height: 46px;
      border-radius: 5px;

      &-active {

        &-heigh {
          background: #FF4D7D 0% 0% no-repeat padding-box;
          color: #FFFFFF;
        }

        &-medium {
          background: #FFCC00 0% 0% no-repeat padding-box;
          color: #FFFFFF;

        }

        &-negative {
          background: #18C48F 0% 0% no-repeat padding-box;
          color: #FFFFFF;

        }
      }

      &-inactive {
        background: #FFFFFF !important;
        color: #383838;
      }

      &-container {
        width: 235px;
      }
    }

    &-date {
      font-size: 18px;
      font-weight: bold;
      line-height: 15px;
    }

    &-filter {
      &-container {
        width: 340px;
      }

      &-region {
        width: 146px;
        margin-left: 17px;
        }

      &-category {
        margin-left: 7px;
        min-width: 168px;
      }
    }

    &-table {

      &-heading {
        font: normal normal normal 15px/36px Roboto;
        margin-top: 10px;
      }

      &-flag {
        margin-right: 6px;
        box-shadow: 1px 1px 2px #00000029;
        height:auto;
      }

      &-relevence {
        color: #ff4d7d;
        font-weight: 100;
      }

      &-company {
        color: #326EAC;
      }

      &-header {
        th {
          height: 42px;
          padding: 8px 18px;
          font-family: Roboto;
          font-size: 15px;
        }

        :nth-child(1) {
          width: 120px;
        }

        :nth-child(2) {
          width: 100px;
        }

        :nth-child(3) {
          width: 310px;
        }

      }

      &-event {

        &-wide {
          min-width: 450px;
        }
      }

      &-body {
        tr {
          td {
            height: 42px;
            font-size: 15px;
            font-family: Roboto;
            padding: 8px 18px;
          }

        }
      }


    }

    &-collapsable-table {

      &-heading {

        th {
          height: 42px !important;
          padding: 8px 18px;
          font-size: 15px;
        }

        :nth-child(1) {
          min-width: 30px !important;
        }

        :nth-child(2) {
          min-width: 102px !important;
        }

        :nth-child(3) {
          min-width: 100px;
        }
        @media screen and (min-width:1100px) and (max-width:1279px){
          :nth-child(4) {
            min-width: 400px !important;
          }
        }
        @media screen and (min-width:699px) and (max-width:1099px){
          :nth-child(4) {
            max-width: 200px !important;
          }
        }
          :nth-child(4) {
            min-width: 600px;
          }

        :nth-child(6) {
          min-width: 80px;
        }

        :nth-child(7) {
          padding-right: 50px;
        }
      }

      &-body {
        tr {
          td {
            height: 45px;
            padding: 8px 18px;
            font-size: 15px;
            font-family: Roboto;

            span {
              height: 100%;
              font-size: 15px;
              font-family: Roboto;
            }
          }

          :nth-child(7) {
            min-width: 78px !important;
            padding-right: 12px;

          }
        }
      }

      &-dropdown-row {
        width: 531px;
        height: 130px;
        padding-bottom: 26px;
      }

      &-description-cell {
        padding-top: 0px !important;

        @media screen and (min-width:1100px) and (max-width:1279px) {
          div {
            width: 382px !important;
            padding-top: 0px !important;
          }
        }
        div {
          width: 531px;
          padding-top: 0px !important;
        }
      }

      &-indicator {
        padding: 25px 0 0 50px !important;

      }

      &-event {

        &-icon {
          &-container {
            padding: 4px 0 0 !important;
          }

          img {
            margin-left: 5px;
          }

          &_upward {
            img {
              height: 33px;
              width: 33px;
              transform: rotate(180deg);
              transition: transform .4s ease-in-out;
            }
          }

          &_downward {
            img {
              height: 33px;
              width: 33px;
              transition: transform .4s ease-in-out;
            }
          }

          :hover {
            cursor: pointer;
          }
        }


      }

      &-border-light {
        border-top: 1px solid #f1f1f1;
      }

      &-border-dark {
        border-top: 1px solid darkgray !important;
      }

      &-border-top-dark {
        border-top: 1px solid darkgray !important;
      }

      &-border-bottom-dark {
        border-bottom: 1px solid darkgray;
      }
    }
  }
}

.t-termine-page {
  &-content-wrapper {
    padding: 8px !important;
    background-color: white;
    box-shadow: #00000029 0px 3px 6px;
  }

  &-header-content {
    padding: 0px 11px;

    h1 {
      font-size: 24px;
      font-family: "Roboto Slab";
      font-weight: bold;
    }

    &-card {
      width: 736px;
      height: 47px;
      border-radius: 5px;
      margin-top: 20px;
      background: linear-gradient(137deg, #f8f8f8 60%, #d4d4d4 100%);

      span {
        font-size: 15px;
      }

    }
  }
  @media screen and (min-width:699px) and (max-width:1099px) {
    &-calender-card {
      width: 396px !important;
  
      &-description {
        width: 260px !important;
      }
    }  
  }

  &-calender-card {
    width: 464px;

    &-description {
      width: 256px;
      margin-left: 4px !important;

      &-element {
        padding-right: 1.5px;
      }
    }
  }

  &-datepicker-container {

    min-width: 45% !important;

    div {
      span {
        font-size: 15px !important;
      }
    }
  }

  &-table {
    &-header {
      th {
        height: 42px;
        font-size: 15px;
        font-family: Roboto;
        font-weight: bold;
        padding: 10px;
      }

      :nth-child(1) {
        width: 85px;
      }
    }

    &-body {
      tr {
        td {
          font-size: 15px;
          font-family: Roboto;
          padding: 10px 10px 12px 10px;
        }
      }
    }

    &-data {
      &-heading {
        th {
          height: 42px;
          padding: 0px 19px 0 0;
          vertical-align: top !important;

        }
      }

      &-body {
        tr {
          margin-top: -10px;
        }
      }
    }
  }

  &-collapsable-table {
    &-heading {
      min-width: 720px;

      th {
        font-size: 15px;
        font-family: Roboto;
        height: 42px;
        padding: 8px 10px 8px 10px;

      }

      &-termin {
        min-width: 496px;
      }

    }

    &-body {
      tr {
        height: 42px;

        td {
          height: 100%;
          padding: 12px 8px 8px 8px;
          font-size: 15px;
          font-family: Roboto;
        }
      }

      &-region {
        min-width: 60px !important;
      }
    }

    &-description {
      &-container {
        @media screen and (min-width:699px) and (max-width:1099px) {
          max-width: 620px !important;
        }
        max-width: 690px;

        &-text {
          max-width: 427px;
          font-size: 14px;
        }

        &-table {
          min-width: 270px;
          margin-left: 20px;
          max-height: 400px;
          padding-left: 30px;
          padding-bottom: 20px;

          &-table {
            min-width: 270px !important;
          }

          &-header {
            th {
              padding-left: 0;
              padding-bottom: 0px;
              padding-right: 0px;
              padding-top: 0;
              vertical-align: top !important;
              line-height: 20px;
              max-height: 30px !important;
            }

            :nth-child(2) {
              min-width: 80px;

            }

            :nth-child(1) {
              min-width: 80px;

            }

            :nth-child(3) {
              min-width: 80px;
            }
          }

          &-body {
            td {
              line-height: 2px !important;
              padding-top: 0px !important;
              padding-left: 2px;
              padding-bottom: 20px;
              width: 80px !important;
            }
          }
        }
      }

      &-event {
        padding-right: 2px !important;
      }
    }

    &-flag {
      margin-right: 6px;
      box-shadow: 1px 1px 2px #00000029;
      height: 12.64px !important;
      width: auto !important;
    }
  }

  &-indicator {
    &-red {
      color: #FF4D7D;
      font-size: 15px;
      font-family: Roboto;
      font-weight: bold;
      width: 220px;
      margin-top: -25px;
      padding-left: 8px;
    }

    &-green {
      color: #18C48F;
      font-size: 15px;
      padding-left: 8px;
      font-family: Roboto;
      font-weight: bold;
      width: 220px;
      margin-top: -25px;
    }

    &-grey {
      color: #383838;
      font: normal normal bold 15px/36px Roboto;
      width: 210px;
      margin-right: 20px;
    }

    &-icon {
      height: 29px;
      width: 30px;
    }
  }

  &-table-data {
    padding: 0;
  }

  &-land {
    width: 100px !important;
  }
}

.m-termine-page {

  &-section-heading {
    font: normal normal bold 18px/24px Roboto Slab;
    margin-top: 30px !important;
  }

  &-header-content {
    padding: 0px 8px 2px 8px;

    h1 {
      padding-top: 14px;
      padding-bottom: 7px;
      font: normal normal bold 20px/26px Roboto Slab;
    }

  }

  &-calender-card {
    width: 200px;

    &-description {
      width: 132px;
    }
  }

  &-table {
    &-header {
      tr {
        th {
          height: 40px;
          font-size: 15px;
          font-family: Roboto;
          font-weight: bold;
          line-height: 18px;
          padding-top: 10px;
          padding-bottom: 10px;
        }

        :nth-child(1) {
          padding-left: 8px;
          padding-right: 10px;
        }

        :nth-child(2) {
          padding-left: 0;
          padding-right: 10px;
        }

        :nth-child(3) {
          padding-left: 10px;
        }
      }
    }

    &-body {
      tr {

        td {
          padding: 12px 0 0px 8px;
          font-family: Roboto;
          font-size: 15px;
          line-height: 20px;
          font-weight: 100 !important;
        }
      }

      &-termin-row {
        td {
          padding-top: 8px !important;
          padding-bottom: 12px !important;
        }
      }
    }
  }

  &-collapsable-table {
    &-header {
      tr {
        th {
          height: 40px;
          font-size: 15px;
          font-family: Roboto;
          font-weight: bold;
          line-height: 18px;
          padding-top: 10px;
          padding-bottom: 10px;
        }

        :nth-child(1) {
          padding-left: 8px;
          padding-right: 10px;
          max-width: 78px;
        }

        :nth-child(2) {
          width: 70px;
        }

        :nth-child(3) {
          width: 220px;
          padding-left: 10px;
        }
      }
    }

    &-body {
      tr {
        td {
          padding: 12px 0 0px 8px;
          font-family: Roboto;
          font-size: 15px;
          line-height: 20px;
        }
      }
    }

    &-termin-text {
      padding-top: 0 !important;
      line-height: 18px !important;
      font-weight: bold;
      padding-bottom: 16px !important;
    }

    &-description {
      padding-top: 16px !important;
      font-size: 14px;
      line-height: 20px;

      &-table {
        &-container {
          padding-left: 8px;
          padding-top: 18px;
          padding-bottom: 22px;
        }

        &-header {

          th {
            padding: 0 !important;
          }

        }

        &-body {

          td {
            padding: 2px 0 0 2px;
          }

        }
      }

      &-indicator {

        &-red {
          color: #FF4D7D;
          font: normal normal bold 15px/36px Roboto;
          width: 210px;
          margin-right: 20px
        }

        &-green {
          color: #18C48F;
          font: normal normal bold 15px/36px Roboto;
          width: 210px;
          margin-right: 20px;
        }

        &-grey {
          color: #383838;
          font: normal normal bold 15px/36px Roboto;
          width: 190px;
          margin-right: 20px;
        }

        &-icon {
          height: 29px;
          width: 30px;
        }

      }
    }

  }

  &-modal {

    &-heading {
      font: normal normal bold 14px/19px Roboto;
    }

    .termine-page-button {
      width: 109px;
      height: 46px;
      border-radius: 5px;

      &-inactive {
        background: #f1f1f1 !important;
        color: #383838;
      }

      &-active {

        &-heigh {
          background: #FF4D7D 0% 0% no-repeat padding-box;
          color: #F1F1F1;
        }

        &-medium {
          background: #FFCC00 0% 0% no-repeat padding-box;
          color: #FFFFFF;

        }

        &-negative {
          background: #18C48F 0% 0% no-repeat padding-box;
          color: #FFFFFF;

        }
      }

    }

  }

}

.relevance {
  &-high {
    color: #FF4D7D;
  }

  &-medium {
    color: #FFCC00;
  }

  &-low {
    color: #18C48F;
  }
}

.m-termine-page-modal-footer-button {
  height: 21px;

  span {
    width: 149px;
    color: var(--ft-blau-links-326eac);
    font: normal normal bold 16px/21px Roboto;
  }
}

.termine-page-indicator {
  &-red {
    color: #FF4D7D;
    font: normal normal bold 15px/36px Roboto;
    width: 200px;
  }

  &-green {
    color: #18C48F;
    font: normal normal bold 15px/36px Roboto;
    width: 200px;
  }

  &-grey {
    color: #383838;
    font: normal normal bold 15px/36px Roboto;
    width: 190px;
    margin-right: 20px;
  }

  &-icon {
    height: 29px;
    width: 30px;
  }
}

.termin-mobile-height {
  height: 359px !important;
}

.mw-720 {
  min-width: 78px;
}

.w-120 {
  width: 120px;
}

.mw-535 {
  width: 535px;
}

.m-content-section {
  padding-top: 0 !important;
}

.termine-page-content {
  min-height:  450px;
}

// .inner-container-width{
//   width: 100%!important;

//   .width-75{
//     width: 75%!important;

//     .termine-page-calender-card{
//       width: 75%;
//     }
//     .termine-page-calender-card-description{
//       width: 24%;
//       margin-left: 0px!important;
//     }
//   }
//   .termine-page-header-content-card {
//     width: 24%!important;
//   }

// }

.table-min-width{
  min-width: 1300px;
}

.termine-page-container{
  max-width: 1280px;
}