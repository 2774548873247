$default-size: 1em;

@mixin size($width, $height) {
    height: $height;
    width: $width;
}

@mixin draw-progress($progress, $color) {
    .pie {
        .half-circle {
            border-color: $color;
        }

        .left-side {
            transform: rotate($progress * 3.6deg);
        }

        @if $progress <= 50 {
            .right-side {
                display: none;
            }
        } @else {
            clip: rect(auto, auto, auto, auto);

            .right-side {
                transform: rotate(180deg);
            }
        }
    }
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin rotate($rotation) {
    -webkit-transform: rotate($rotation);
    -moz-transform: rotate($rotation);
    -ms-transform: rotate($rotation);
    -o-transform: rotate($rotation);
    transform: rotate($rotation);
}

@mixin box-sizing($box-sizing) {
    -webkit-box-sizing: $box-sizing;
    -moz-box-sizing: $box-sizing;
    box-sizing: $box-sizing;
}
@mixin transition-property($transition) {
    -webkit-transition-property: $transition;
    -moz-transition-property: $transition;
    -o-transition-property: $transition;
    transition-property: $transition;
}

@mixin transition-duration($duration) {
    -webkit-transition-duration: $duration;
    -moz-transition-duration: $duration;
    -o-transition-duration: $duration;
    transition-duration: $duration;
}

@mixin transition-timing-function($timing) {
    -webkit-transition-timing-function: $timing;
    -moz-transition-timing-function: $timing;
    -o-transition-timing-function: $timing;
    transition-timing-function: $timing;
}
