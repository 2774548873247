@import 'src/styles/theme';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.card-title {
    display: -webkit-inline-box;
    overflow: hidden;
    font-size: 15px;
    line-height: 18px;
    font-weight: bold;
    color: theme-color("dark");
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
