.table-figures {
  thead {
    th {
      background-color: #f1f1f1;
      border: 0;
      &.value {
        min-width: 90px;
      }
      text-align: right;
    }
  }
  th, td {
    padding: 8px 4px;
    font-size: 14px;
    &:not(:first-child) {
      text-align: right;
    }
  }
}
