@import "src/styles/mixins";
@import "src/styles/variables";
@import "src/styles/theme";

@media screen and (min-width:1100px) and (max-width:1279px) {
  .banner-container{
    max-width: 1280px !important;
  }
  .most-traded-font{
    font-size: 13px !important;
  }
  
}
@media screen and (min-width:1280px) {
  .banner-container{
    max-width: 1280px !important;
  }
  
}
@media screen and (max-width:699px){
  .blog-teaser-title{
    font: normal normal normal 13px/18px Roboto !important;
  }
  .blog-teaser-headline{
    font: normal normal bold 18px/21px Roboto !important;
  }
}
.blog-teaser-details{
  background: #0D5A94 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  padding: 2%;
}
.blog-teaser-headline{
  text-align: left;
  font: normal normal bold 24px/28px Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  text-overflow: clip; 
}
.blog-teaser-title{
  text-align: left;
  font: normal normal normal 18px/18px Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}
.blog-teaser-open-button{
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  text-align: center;
  color: #326EAC;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
}
.blog-teaser-timeago{
  text-align: right;
  font: normal normal normal 13px/18px Roboto;
  letter-spacing: 0px;
  color: #FFFFFF99;
  opacity: 1;
}

.blog-teaser-image {
  background-position: 25% center;
  background-size: cover;
  background-color: rgb(255, 255, 255);
  @media screen and (max-width:698px){
    aspect-ratio: 16/9 !important;
  }
  @media screen and (min-width:699px) {
    height: 332px;
  }


}

.home-statistics-mf{
  .custom-padding-right{
    padding-right: 10px !important;
    @media screen and (max-width: map-get($grid-breakpoints, xl) - 1) {
      padding-right: 16px !important;
    }
  }
  .custom-padding-left{
    padding-left: 6px !important;
    @media screen and (max-width: map-get($grid-breakpoints, xl) - 1) {
      padding-left: 16px !important;
    }
  }
}

.blur-text-4px{
  filter: blur(4px);
  -webkit-filter: blur(4px);
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.carousel-inner-hidden{
  .carousel-inner{
    overflow: hidden !important;
  }
}
.most-traded-section{
  height: 450px;
  line-height: 1.4;
  .trading-title{
    font-size: 23.5px;
  }
  @media screen and (max-width: map-get($grid-breakpoints, xl) - 1) {
    height: 250px !important;
    .button-position{
      text-align: right;
    }
    .trading-title{
      font-size: 24px;
    }
    .border-lg-gray{
      border-left: 1px solid;
      border-color: #ffffff;
    }
  }
  @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
    height: 443px !important;
    .button-position{
      text-align: left !important;
    }
    .trading-title{
      font-size: 22px;
    }
    .border-lg-gray{
      border-left: 0px solid;
    }
  }
}

.carousel-item-height{
    height: 450px;
  .carousel-item-inner-img-height{
    height: 350px;
  }
  .border-left-1{
    display: none;
  }
  @media screen and (max-width: map-get($grid-breakpoints, xl) - 1) {
    height: 480px !important;
    .carousel-item-inner-img-height{
      height: 300px !important;
    }
    .title-line-height{
      line-height: 1.2;
    }
    .body-line-height{
      line-height: 1.3;
    }
  }
  @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
    height: 430px !important;
    .carousel-item-inner-img-height{
      height: 180px !important;
    }
    .border-left-1{
      display: none;
    }
  }
}

.statistics{
  .title{
    padding-left: 5px !important;
    font-size: 15px !important;
  }
  .text-value{
    bottom: 1px;
  }
  .chart-value{
    height: 12px !important;
  }
  .asset-name{
    padding-left: 5px !important;
  }
}

.modal-statistics{
  .title{
    font-size: 24px !important;
    padding-left: 5px !important;
    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
      padding-left: 7px !important;
    }
  }
  .text-value{
    font-weight: bold;
    bottom: 1px;
    font-size: 15px !important;
    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
      font-size: 13px !important;
    }
  }
  .chart-value{
    height: 16px !important;
    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
      height: 12px !important;
    }
  }
  .statistics-col{
    margin-top: 6px !important;
    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
      margin-top: 2px !important;
    }
  }
  .asset-name{
    padding-left: 5px !important;
    font-size: 15px !important;
    margin-top: 6px !important;
    @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
      padding-left: 7px !important;
      font-size: 13px !important;
      margin-top: 2px !important;
    }
  }
}

.border-button{
  text-decoration: none !important;
  border-radius: 0 !important;
  border: #0d5a94 0px solid !important;
  border-bottom: #0d5a94 2.3px solid !important;
  &.active{
    border-bottom: white 2.3px solid !important;
  }
}