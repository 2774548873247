@media screen and (min-width:1100px) and (max-width:1279px){
  .termine_teaserbox_container{
    max-width: 700px;
  }
  .termine_teaserbox_news-section{
    max-width: 495px !important;
  }
  
}

@media screen and (min-width:1100px) and (max-width:1100px){
  .termine_teaserbox_container{
    max-width: 650px;
  }
  .termine_teaserbox_news-section{
    max-width: 370px !important;
  }
  
}

.termine_teaserbox {

  &-news_container {
    padding-left: 15.5px;
    padding-right: 16.5px;

    &_heading {
      font-family: "Roboto Slab";
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 7.5px !important;
    }
  }

  &_news-section {
    width: 406px;
  }

  &_container {
    padding: 22px 16px;
    width: 826px;
    height: 321px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    margin-right: 8px;

    &_corporate-events, &_economic-events {
      padding-top: 8px;
      height: 234px;
      width: 389px;
    }

    &_events {

      &-wrapper {
        height: 48px;
        margin-bottom: 10px;
      }

      &_region-wrapper {
        height: 20px;
        font: normal normal normal 13px/16px Roboto;

        p {
          height: 100%;
        }

        p:nth-child(1), p:nth-child(2) {
          margin-right: 16px;
        }
      }

      &_region {
        width: 40px;

        img {
          box-shadow: 1px 1px 2px #00000029;
        }

        span {
          padding-left: 5.2px;
        }
      }

      &-event {
        height: 20px;
        @media screen and (min-width:1100px) and (max-width:1279px){
          font: normal normal bold 13px/18px Roboto !important;
        }
        font: normal normal bold 15px/18px Roboto;
      }

      &-company {
        color: #326EAC;

        span:hover {
          cursor: pointer;
        }
      }


    }

    &-button {
      button {
        background: #5B9DE1 0% 0% no-repeat padding-box;
        border-radius: 3px;
        color: white !important;
        padding: 6px 9px;
        font: normal normal normal 14px/19px Roboto !important;
        height: 31px;
        width: 119px;
      }

      button :hover {
        color: white;
      }

      span {
        width: 87px;
        height: 19px;
      }
    }
  }

  &_link :hover {
    cursor: pointer;
  }
}

.t-termine_teaserbox {

  &-news_container {
    padding-left: 15.5px;
    padding-right: 16.5px;

    &_heading {
      font-family: "Roboto Slab";
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 7.75px !important;
    }

  }

  &_container {
    padding: 17px 16px;
    width: 736px;
    height: 291px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    margin-bottom: 10px;

    &_corporate-events, &_economic-events {
      height: 234px;
      width: 352px;
    }

    &_corporate-events {
      margin-right: 16px;
    }

    &_events_region-wrapper {
      height: 20px;
      font: normal normal normal 13px/16px Roboto;

      p {
        height: 100%;
      }

      p:nth-child(1), p:nth-child(2) {
        margin-right: 15px;
      }
    }
  }
}

.m-termine_teaserbox {
  &-news_container {
    &_heading {
      padding-left: 7.41px;
      font: normal normal bold 20px/24px Roboto Slab;
    }
  }

  &_container {
    padding-left: 7.41px;
    padding-right: 8px;
    padding-bottom: 11px;
    margin-bottom: 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    padding-top: 16.51px;
    height: 526px;

    &_events_region-wrapper {
      height: 20px;
      font: normal normal normal 13px/16px Roboto;

      p {
        height: 100%;
      }

      p:nth-child(1), p:nth-child(2) {
        margin-right: 15px;
      }
    }
  }

}

.termine_teaserbox-button {
  padding-top: 10px;
}

.termine_teaserbox_news-section {

  @media (max-width: 1300px) and (min-width: 1290px) {
   width: 400px ;
  }

  @media (max-width: 1290px) and (min-width: 1280px) {
    width: 394px;
  }

  @media (max-width: 1279px) and (min-width: 699px) {
    width: 736px !important;
  }
}