@import "src/styles/theme";
@import "~bootstrap/scss/mixins/breakpoints";

.range-selector {
  .card-body-wrapper {
    width: 320px;
  }
  .value-input {
    .label {
      line-height: 32px;
    }
    .input-group {
      background: #f1f1f1;
    }
  }
}

.range-selector-160 {
  min-width: 160px;
  @media screen and (width: map-get($grid-breakpoints,xl) - 1){
    width: 160px !important;
  }

}
