@import 'src/styles/theme';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.top-flop-card {
    display: grid;
    grid-template: "quotes" auto
                   "table" auto
                   "cards" auto
                   "link" auto
                   / 100%;
   gap: 24px 0;
    @media screen and (min-width: map-get($grid-breakpoints, md) - 1) {
        grid-template: "quotes" auto
                       "table" auto
                       "cards" auto
                       "link" auto
                       / 100%;
        gap: 32px 0;
    }
    @media screen and (min-width: map-get($grid-breakpoints, xl) - 1) {
        grid-template: "table cards" auto
                       "quotes link" auto
                       / 3fr 1fr;
        gap: 20px 0;
    }
}
.table-top-flop {
    width: 344px;
    @media screen and (min-width: map-get($grid-breakpoints, md) - 1) {
        width: 720px;
    }
    @media screen and (min-width: map-get($grid-breakpoints, xl) - 1) {
        width: 900px;
    }
    table-layout: fixed;
    grid-area: table;
    border-bottom: 1px solid #dee2e6;

    tr {
        border-top: 1px solid theme-color("gray-light");
        border-bottom: 1px solid theme-color("gray-light");
    }
    td:nth-child(1) {
        width: 177px;
        padding-right: 4px;
    }
    td:nth-child(2) {
        width: 94px;
        padding-right: 4px;
    }
    td:nth-child(3) {
        width: 73px;
    }
    @media screen and (min-width: map-get($grid-breakpoints, md) - 1) {
        td:nth-child(1) {
            width: 187px;
            padding-right: 8px;
        }
        td:nth-child(2) {
            width: 94px;
            padding-right: 4px;
        }
        td:nth-child(3) {
            width: 66px;
            padding-right: 8px;
        }
        td:nth-child(4) {
            width: 102px;
        }
        td:nth-child(5) {
            width: 186px;
            padding-left: 4px;
        }
        td:nth-child(6) {
            width: 85px;
        }
    }
    @media screen and (min-width: map-get($grid-breakpoints, xl) - 1) {
        td:nth-child(1) {
            width: 220px;
            padding-right: 8px;
        }
        td:nth-child(2) {
            width: 90px;
            padding-right: 4px;
        }
        td:nth-child(3) {
            width: 94px;
            padding-right: 8px;
        }
        td:nth-child(4) {
            width: 196px;
        }
        td:nth-child(5) {
            width: 205px;
            padding-left: 8px;
        }
        td:nth-child(6) {
            width: 95px;
        }
    }

    .delimiter {
        td {
            border-top-width: 3px;
        }
    }
    .progress-bar {
        @media screen and (min-width: map-get($grid-breakpoints, md) - 1) {
            width: 160px;
        }
        @media screen and (min-width: map-get($grid-breakpoints, xl) - 1) {
            width: 246px;
        }
        height: 8px;
    }
}
.snap-quote-row {
    @media screen and (max-width: map-get($grid-breakpoints, sm)) {
        &__link {
            flex-basis: 86px;
        }
        &__delay {
            flex-basis: 22px;
        }
        &__trade-value {
            flex-basis: 88px;
        }
        &__pct-change-value {
            flex-basis:  54px;
        }
        &__time-value {
            flex-basis: 55px;
        }
    }
    @media screen and (min-width: map-get($grid-breakpoints, md) - 1) and (max-width: map-get($grid-breakpoints, md)) {
        &__link {
            flex-basis: 86px;
        }
        &__delay {
            flex-basis: 22px;
        }
        &__trade-value {
            flex-basis: 88px;
        }
        &__pct-change-value {
            flex-basis:  54px;
        }
        &__time-value {
            flex-basis: 55px;
        }
    }
}

.quotes {
    grid-area: quotes;
}
.cards {
    grid-area: cards;
}
.individual-link {
    grid-area: link;
}
    .top-flop-product-card {
        width: 344px;
        @media screen and (min-width: map-get($grid-breakpoints, md) - 1) {
            width: 352px;
        }
        @media screen and (min-width: map-get($grid-breakpoints, xl) - 1) {
            width: 300px;
        }
    }
