@import "../../../styles/theme.scss";

#horizontal-navigation-bar{
    .no-scrollbar-x {
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        overflow-x: scroll;
    }

    .no-scrollbar-x::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }

    .gradient {
        background: transparent linear-gradient(92deg, #484848 0%, #6A6A6A 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        opacity: 1;
    }

    .left-arrow-bg {
        background-color: #484848;
        width: 34px !important;
        position: absolute;
        left: 0;
    }

    .right-arrow-bg {
        background-color: #6A6A6A;
        width: 34px !important;
        position: absolute;
        right: 0;
    }
    .ul-nav-bar{
        min-width: 100%;
    }

    @media screen and (min-width: map-get($grid-breakpoints,xl)) {
        .ul-nav-bar {
            ms-overflow-style: none; /* for Internet Explorer, Edge */
            scrollbar-width: none; /* for Firefox */
            overflow-x: scroll;
        }
    }

    @media screen and (max-width: map-get($grid-breakpoints,xl) - 1){
        .left-arrow-bg {
            display: none;
        }

        .right-arrow-bg {
            display: none;
        }

    }

    .horizontal-navbar {
        text-align: center;
        width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
        padding-left: 0px;
        margin-left: 0;
    }

    ul{
        display: flex;
        line-height: 44px;
        align-items: center;
        list-style: none;
        max-width: 100%;
        overflow-x: scroll;
        scroll-behavior: smooth;
        margin: 0;padding-left: 0.5rem;
        &::-webkit-scrollbar {
            background: transparent; /* make scrollbar transparent */
            -webkit-appearance: none;
            width: 0;
            height: 0;
        }
        li{
            color: whitesmoke;
            background: transparent;
            margin-right: 16px;
            white-space: nowrap;
        }
    }

    button{
        border: 0;
        color: #777;
        font-size: 24px;
        margin: 0;
    }
}