@import 'src/styles/theme';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";

@media screen and (min-width:1100px) and (max-width:1279px){
    .limits-button-container{
        display: flex;
        align-items: center;
    }
    .filter-btn-container{
        margin-left: -50px !important;
    }
    
}

.limit-label{
    padding: 2px 8px;
    color: white;
    border-radius: 0.3em;
    z-index: 15;
}

.down-limit{
    height: 28px !important;
    border-bottom: 0 !important;
    background-color: #FF4D7D !important;
}

.upper-limit{
    height: 28px !important;
    border-bottom: 0 !important;
    background-color: #18C48F !important;
}

.yellow-line{
    background-color: #FFCC00;
}

@keyframes pulse_ani {
    0% { background:#18C48F;box-shadow: 0px 0px 8px rgba(0,0,0,0.6) inset;}
    10% { background:#00ff15;box-shadow: 0px 0px 2px rgba(0,0,0,0.6) inset; }
    20% { background:#00ff15;box-shadow: 0px 0px 2px rgba(0,0,0,0.6) inset; }
    80% { background:#18C48F;box-shadow: 0px 0px 8px rgba(0,0,0,0.6) inset; }
    100% { background:#18C48F;box-shadow: 0px 0px 8px rgba(0,0,0,0.6) inset; }
}

.pulse {
    width:16px;
    height:16px;
    background:#18C48F;
    border-radius: 50%;
    position:absolute;
    right:12px;
    bottom:12px;
    animation: pulse_ani 3s infinite;
}

.green-dot{
    width:12px;
    height:12px;
    background:#18C48F;
    border-radius: 50%;
    position: fixed;
    margin-top: 5px;
}

.add-limit-card{
    margin-top:16px;
    .lollipop-card{
        min-height: 232px !important;    
    }

    @media screen and (max-width: map-get($grid-breakpoints,xl) - 1){
        .lollipop-card{
            margin-right: 0 !important;
            margin-left: 16px !important;
            min-height: 217px !important;    
        }
    }
    @media screen and (max-width: map-get($grid-breakpoints,md) - 1){
        margin-top: 16px;
        .lollipop-card{           
            min-width: 357px;
            min-height: 217px !important;    
            margin-left: -22px !important;
        }
    }
}
.btn-limit-comp {
    height: 29px;
    width:119px;
    color:#326EAC !important;
    font-weight: bold;
}
.btn-limit-comp-right {
    height: 29px;
    width:211px;
    color: #326EAC !important;
    font-weight: bold;
}
.btn-limit-modal-left {
    width: 99px;
    height: 31px;
}

.btn-limit-modal-right {
    width: 81px;
    height: 31px;
}

.modal-dialog-container {
    .modal-dialog {
        width: 752px;
        height: 193px;
    }
}

.limits-table-modal-body {
    width: 100%;
    height: 64px;
    flex-direction: column;
    line-height: 2;
}
.kurse-font-color{
color:#383838 !important;
}
.btn-limit-comp-delete{
width:98px;
height:29px;
color:white;
background: #FF4D7D !important;
}

.btn {
    &.btn-short-button {
        color: var(--white);
        background-color: var(--red);
        padding: 2px 4px;
    }
}
.main-section{
    .heading-with-info{
        display: flex;
        .buttons-in-results{
            &.analyses-buttons-modal{
                div{
                    padding-top: 0 !important
                }
            }
        }
        @media screen and (max-width: map-get($grid-breakpoints,xl) - 1){
            display: inline;
            .buttons-in-results{
                white-space: nowrap;
                margin-top: 10px;
            }
        }
        @media screen and (max-width: map-get($grid-breakpoints,md) - 1){
            .buttons-in-results{
                &.analyses{
                    margin-top: 10px;
                    margin-left: 16px !important;
                }
                button{
                    padding-left: 8px;
                    padding-right: 8px;
                }
                &.analyses-buttons-modal{
                    margin-left: -16px !important;

                    .first-section{
                        padding: 16px !important;
                        div{
                            padding: 0 !important
                        }
                        div:last-child{
                            padding-left: 8px !important
                        }
                    }
                }
            }
        }
    }
}
.buttons-in-results{
    div{
        button:last-child{
            margin-right: 0;
        }
        button{
            background-color: white;
            color: #326EAC;
            margin-right: 6px;
            .active-img{
                display: none;
            }
            .inactive-img{
                display: inline;
            }
            &.active, &:hover{
                .inactive-img{
                    display: none;
                }
                .active-img{
                    display: inline;
                }
                color: white;
                background-color: #5B9DE1;
                &.pos{
                    background-color: #18C48F;
                }
                &.neutr{
                    background-color: #FFCC00;
                }
                &.neg{
                    background-color: #FF4D7D;
                }
                &.primary{
                    background-color: #FF4D7D;
                }
            }
        }
    }
}