
@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
}

.contact-button{
    position: absolute;
    margin-top: -60px;
    width: 150px;
    size: 16px;
    background-color: rgba(33, 37, 41, 0.5);
    border: 1px solid black;
    background: linear-gradient(
180deg
, #ffffff 0%, #DEDEDE 100%) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    box-shadow: 2px 2px 6px #00000080;
    padding: 0.3rem 0.7rem;
    cursor: pointer;
}

.go-to-top-button{
    position: absolute;
    right: 0;
    margin-top: -60px;
    width: 52px;
    height: 52px;
    size: 16px;
    background-color: rgba(33, 37, 41, 0.5);
    background: linear-gradient(180deg, #ffffff 0%, #DEDEDE 100%) 0% 0% no-repeat padding-box;
    border-radius: 50px;

    box-shadow: 2px 2px 6px #00000080;
    padding: 8px;
    padding-bottom: 10px;
    margin-right: 8px;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.7;
}

.go-to-top-button:hover{
    opacity: 1;
}

.scroll-button{
    position: absolute;
    margin-top: -60px;
}