@import "../mixins.scss";
@import "../variables.scss";

.pagination {
    &.no-border {
        .page-link {
            border: none;
        }
    }

    &.pagination-circle {
        .page-item {
            &.active {
                .page-link {
                    font-weight: bold;
                }
            }

            .page-link {
                border-radius: 50% !important;
                padding: 0;
                margin: 0;
                width: 28px;
                height: 28px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .page-item {
        + .page-item {
            margin-left: 15px;
        }

        .page-link {
            line-height: 1;
            font-size: 14px;
            color: $body-color;
        }
    }
}

.pagination-dash-only {
    ul {
        li {
            box-sizing: content-box;
            flex: 0 1 auto;
            width: 9px;
            height: 2px;
            margin-right: 3px;
            text-indent: -999px;
            cursor: pointer;
            background-color: var(--gray-dark);
            background-clip: padding-box;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            opacity: 0.3;
            transition: opacity 0.6s ease;

            &.active {
                opacity: 1;
            }

            &:last-child {
                margin-right: 0;
            }

            + li {
                margin-left: 3px !important;
            }
        }
    }
}

.pagination-mobile-only {
    ul {
        li {
            box-sizing: content-box;
            flex: 0 1 auto;
            width: 9px;
            height: 2px;
            margin-right: 3px;
            text-indent: -999px;
            cursor: pointer;
            background-color: var(--gray-dark);
            background-clip: padding-box;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            opacity: 0.3;
            transition: opacity 0.6s ease;

            &.active {
                opacity: 1;
            }

            &:last-child {
                margin-right: 0;
            }

            + li {
                margin-left: 3px !important;
            }
        }
    }
}
