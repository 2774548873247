
.bg-lighten-gray{
  background-color: #F1F1F1;
}

.text-ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.large{
    max-width: 330px;
  }
  &.small{
    max-width: 170px;
    font-size: 15px;
  }
}


.positive{
  padding: 11px 21px !important;
  //width: 100px !important;
  &.in-modal{
    padding: 10px 35px !important;
  }
}

.negative{
  padding: 11px 18px !important;
  //width: 100px !important;
  &.in-modal{
    padding: 10px 33px !important;
  }
}

.neutral{
  padding: 11px 16px !important;
  //width: 100px !important;
  &.in-modal{
    padding: 10px 30px !important;
  }
}