.derivative-suche-text-fix{
    transform: translate(-7px,0px) !important;
}
.derivative-suche-text-fix-short{
    transform: translate(-8px,0px) !important;
}
.long-filter-btn{
    max-width:50px !important;
    margin-right: 5px !important;
}
.short-filter-btn{
    max-width: 50px !important;
}
.derivative-suche-text-call{
    transform: translate(-3px,0px) !important;
}