.chart-tool-search-modal {
  display: block;
  position: absolute;
  top: 2%;
  right: 0;
  z-index: 100000;
  width: 620px;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  border: 1px solid #989898;
  opacity: 1;

  .chart-tool-modal-search-heading {
    font: normal normal bold 17px/23px Roboto;
    color: #383838;
  }
  .chart-tool-modal-search-border {
    border-bottom: 3px solid #f1f1f1;
    padding-bottom: 10px;
  }

  .all-realtime-index {
    font: normal normal bold 16px/21px Roboto;
    letter-spacing: 0;
    color: #326EAC;
    opacity: 1;
  }

  .chart-tool-modal-search-input {
    font: normal normal normal 15px/20px Roboto;
    letter-spacing: 0;
    color: #383838;
  }

}
