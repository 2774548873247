@import "../../../styles/mixins";
@import "../../../styles/variables";
@import "../../../styles/theme";

.share-search-buttons {
  font-size: 20px;
  padding: 16px 8px;
  border-radius: 5px;
  font-weight: 700 !important;
  @media screen and (max-width: map-get($grid-breakpoints, xl) - 1) {
    font-size: 16px !important;
    padding: 13px 10px !important;
  }
}

.share-table-green-icon {
  width: 14px;
  height: 14px;
}

.share-cards {
  max-width: 360px;

  .card-footer {
    padding-top: 0;
  }
}

.share-name-column {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.large {
    width: 250px;
    max-width: 250px;
  }

  &.medium {
    width: 150px;
    max-width: 150px;
  }
}