@media screen and (min-width:1100px) and (max-width:1279px) {
.currency-find-section-container{
    max-width: 1280px !important;
}    
}
@media screen and (min-width:1280px) {
    .currency-find-section-container{
        max-width: 1280px !important;
    }    
    }
    @media screen and (max-width:1099px) {
        .currency-find-section-container{
            max-width: 786px !important;
        }    
        }
.currency-find-instrument-card{
    height: 142px;
}

.button-styling{
    display: inline !important;
    float: left !important;
}
.modal-header-fix{
    max-height: 50px !important;
}
.fix-modal-dropdown{
    font-size: 1.25rem !important;
}
.fix-line-height{
    line-height: 1.5 !important;
}