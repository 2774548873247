@import '../../../../styles/theme';

.change-password-modal {
    .modal-dialog {
        margin-top: 25% !important;
        @media screen and (min-width: map-get($grid-breakpoints, md) - 1) {
            margin-top: 6.5% !important;
        }
        @media screen and (min-width: map-get($grid-breakpoints, xl) - 1) {
            margin-top: 4.5% !important;
        }
    }
    .modal-md {
        max-width: 468px !important;
    }
    .modal-sm {
        width: 344px !important;
    }
}
