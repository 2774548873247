@import 'src/styles/theme';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";

.dot {
    content: "\A";
    width: 13px;
    height: 13px;
    border-radius: 0.5em;
    display: inline-block;
}

.legend, .legend-big {
    font-size: 15px;

    @media screen and (max-width: map-get($grid-breakpoints, xl) - 1) {
        font-size: 13px !important;
    }

    .inactive {
        color: #cccccc;

        .dot {
            background-color: #cccccc !important;
        }
    }
}

@media only screen and (max-width: 767px) {
    .dropdown-legend-button {
        color: #383838 !important;
        border: none !important;
        background-color: #F1F1F1 !important;
        font-weight: bolder;
        max-width: 70%;

        .dot {
            background-color: black !important;
        }
    }
}

@media only screen and (max-width: 1280px) {
    .dropdown-legend-button {
        color: #383838 !important;
        border: none !important;
        background-color: #F1F1F1 !important;
        font-weight: bolder;
        max-width: 70%;

        .dot {
            background-color: black !important;
        }
    }
}

@media only screen and (min-width: 1281px) {
    .dropdown-flag-style{
        margin-left: 4px !important;
    }
    .dropdown-legend-button {
        color: #383838 !important;
        border: none !important;
        background-color: #F1F1F1 !important;
        font-weight: bolder;
        max-width: 83%;

        .dot {
            background-color: black !important;
        }
    }
}

.dropdown-legend-menu {
    :last-child {
        display: none;
    }
}

.flex-grow-1 .section-heading-devisen {
    font-size: 24px !important;
    float: left !important;
    width: 28.6% !important;
    margin-bottom: 0 !important;
    margin-top: 3px !important;
}

.flex-grow-1 .justify-content-between {
    justify-content: unset !important;
}

