.teaserbox-carousel {
  height: 514.3px;
  width: 316px;

  &_heading {
    font-size: 18px;
    font-family: Roboto Slab;
    margin-bottom: 10px;
  }

  &_wrapper {
    padding-left: 2px;
    height: 394px;
    width: 284px;

    &-borders {
      border-top: 1px solid #38383812;
      border-bottom: 1px solid #38383812;
      width: 100%;
      height: 100%;
      margin-bottom: 4px;

      :nth-child(1) {
        border-top: none;
      }
    }
  }

  &_cell {
    height: 65px;
    width: 100%;
    border-top: 1px solid #38383812;
    padding: 12px 12px 14px 0;
    font-size: 15px;
  }

  &_controls {
    margin-bottom: -38px !important;
  }

  &_link {
    font-size: 14px;
    font-family: Roboto;
    margin-top: 40px !important;
  }
}

.t-teaserbox-carousel {
  height: 237.82px;
  width: 736px;
  background-color: white;
  box-shadow: #00000029 0px 3px 6px;
  padding: 16px 8px;
  margin-top: 16px;

  &_heading {
    font-size: 18px;
    font-family: Roboto Slab;
    margin-bottom: 9px;
  }

  &_wrapper {
    height: 144px;
    width: 751px;
  }

  &_col {
    &_container {
      height: 46px;
      width: 353px;
      border-top: 1px solid #38383812;
      padding: 4px 2px;
      font-family: Roboto;
      font-size: 15px;

      &_borders {
        border-bottom: 1px solid #38383812;
        width: 353px;
        margin-right: 4px;
      }
    }
  }

  &_controls {
    margin-bottom: -35px !important;
  }

  &_link {
    font-family: Roboto;
    font-size: 14px;
    margin-top: 14px;
    z-index: 50;
  }
}

.m-teaserbox-carousel {
  height: 523.3px;
  width: 360px;

  &_wrapper {
    height: 390px;
    width: 100%;
  }

  &_heading {
    font-family: Roboto Slab;
    font-size: 18px;
    margin-top: 2px;
    margin-bottom: 12px;
  }

  &_cell {
    height: 64px;
    width: 100%;
    border-top: 1px solid #38383812;
    padding: 12px 2px 13px 2px;
    font-size: 15px;
    font-family: Roboto;
  }

  &_controls {
    margin-bottom: -44px !important;
  }

  &_link {
    font-size: 14px;
    font-family: Roboto;
    margin-top: 52px !important;
  }
}

