@media screen and (min-width:1100px) and (max-width:1279px) {
    .table-section-container{
        max-width: 1280px !important;
    }
    
}
@media screen and (min-width:1280px){
    .table-section-container{
        max-width: 1280px !important;
    }
    
}
@media screen and (max-width:1099px) {
    .table-section-container{
        max-width: 786px !important;
    }
    
}
.active-table-button {
    text-decoration: underline 2px !important;
}