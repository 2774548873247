@import "/src/styles/theme.scss";

.info-modal {
    .modal-dialog {
        width: 344px;
        @media screen and (min-width: map-get($grid-breakpoints, md)) {
            width: 736px;
        }
    }
    .information {
        width: 24px;
        height: 24px;
        background-color: var(--blue);
        border: 0;
        border-radius: 50%;
        text-align: center;
        font-family: serif;
        font-weight: 900;
        color: #fff;
    }
}
