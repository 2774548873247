@import '../../styles/theme';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";

@media screen and (min-width:1100px) and (max-width:1279px) {
    .currency-over-breadcrum-container{
        max-width: 1280px !important;
    }
    .bottom-news-container{
        max-width: 1280px !important;
    }
    
}
@media screen and (min-width:1280px) {
    .currency-over-breadcrum-container{
        max-width: 1280px !important;
    }
    .bottom-news-container{
        max-width: 1280px !important;
    }
    
}
@media screen and (max-width:1099px) {
    .currency-over-breadcrum-container{
        max-width: 786px !important;
    }
    .bottom-news-container{
        max-width: 786px !important;
    }
    
}
#table-section .active-table-button {
    text-decoration: underline !important;
    width: unset !important;
}
.devisen-overview-page {
    .market-overview-section {
        .market-section-heading {
            h1 {
                font-size: 20px !important;
                padding-bottom: 1rem !important;
            }
        }
    }
}

@media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
    .devisen-overview-page {
        .market-overview-section {
            .market-section-heading {
                h1 {
                    font-size: 24px !important;
                }
            }
        }
    }
}
