

@media screen and (min-width:1100px) and (max-width:1279px) {
  .hot-section-container{
    max-width: 1280px !important;
  }
  
}
@media screen and (min-width:1280px) {
  .hot-section-container{
    max-width: 1280px !important;
  }
  
}
@media screen and (max-width:1099px) {
  .hot-section-container{
    max-width: 768px !important;
  }
  .hot-section-inner-container{
    max-width: 768px !important;
  }
  
}
.derivate-info {
  padding: 7px;
  line-height: 1;
  color: white;

  .info-side {
    div {
      &:first-child {
        font-size: 12px;

        + div {
          font-size: 9px;
          opacity: 0.45;
          margin-top: 2px;
          text-transform: uppercase;
        }
      }
    }
  }

  .buttons-side {
    display: flex;
  }

  button {
    border: 0;
    font-size: 12px;
    font-weight: bold;
    padding: 4px 0 2px 0;
    min-width: 34px;
  }

}
.hot-section-container .section-heading {
  font-size: 24px;
  font-family: "Roboto Slab",sans-serif;
}

.font-weight-bold {
  font-weight: 700 !important;
}

@media screen and (max-width: 767px) {
  .hot-section-container .section-heading  {
    font-size: 20px;
  }
}