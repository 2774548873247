@media print {
    @page { 
        size: landscape;
        margin: 0;
    } 
    * {
       box-sizing: border-box;
       -moz-box-sizing: border-box;
    }
    body {
        margin: 0;
        padding: 16px;
        box-shadow: 0;
        width: 100% !important;
    }

    .container {
        min-width: 100% !important;
        max-width: 100% !important;
        margin: 0;
        padding: 0;
    }
    .main-wrapper {
        background-color: #fff;
    }
    .overflow-auto {
        overflow: visible !important;
    }
    .pr-dn {
        display: none;
    }
   .pr-news-modal {
   position : absolute;
   height:auto;
   }
    section#limits-page-chart{
        display: none;
    }

    .home-banner.mein-finanztreff .carousel .carousel-item,
    .overview-page-banner .carousel-item {
        min-height: auto !important;
    }
    
    .ads-responsive-class-footer,
    .contact-button,
    footer {
        display: none;
    }
}
.change-print-color{
    
}
