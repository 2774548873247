
.regions-filter-modal {
  &-border {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029 !important;
    border: 1px solid #989898 !important;
    border-radius: 0 !important;
  }

  &-body {
    height: 290px !important;
    width: 266px;
    overflow-x: hidden;
    padding: 0px !important;
    overflow-y: scroll;


    div {
      height: 100%;
    }

    .regions-filter-modal-body-button {
      height: 33px !important;
    }

    :hover {
      cursor: pointer;
    }

    &-button {
      line-height: 27px;
      margin-right: 4px;
      font: normal normal normal 15px/20px Roboto;
    }

  }
}

.btn-dropdown-wrapper {
  background: #326EAC 0% 0% no-repeat padding-box !important;
  height: 46px !important;
  border-radius: 5px;
}

.dropdown-header {
  height: 30px;
}

.header-title {
  color: #383838;
}

.customFilterBtn {
  padding: 2px;
  height: 46px;
  width: 140px;
}

.m-regions-filter-modal-body {
  width: 100%;
}

.termine-page-modal-button-title {
  font: normal normal bold 15px/18px Roboto !important;
  color: #FFFFFF !important;
  padding: 1px 0 0 0;
}

.termine-page-modal-button-description {
  font-size: 13px !important;
  line-height: 13px !important;
  color: #FFFFFF !important;
}

.m-termine-page-modal-buttons {
  min-width: 90px !important;
}


.dropdown-btn-text {
  padding: 10px 0 5px 0 !important;

  &-selected {
    padding: 0 0 10px 0 !important;
  }
}

.categorie-dropdown-width {
  min-width: 168px !important;
  min-height: 46px !important;
}

.region-dropdown-width {
  min-width: 146px !important;
}

.d-filter-modal-size {
  height: 290.64px !important;
  width: 276px;
}

.d-modal-button-width {
  width: 94.5%;
}

.t-modal-button-width {
  width: 100%;
}

.t-regions-filter-modal {
  .modal-body {
    padding: 0 !important;
  }

  &-body {
    overflow-y: scroll;

    padding-right: 221px;
    padding-left: 203px;

    &-content {
      max-height: 530px !important;
      padding-left: 6px;
    }
  }
}

.regions-filter-modal-footer-button-container {
  padding: 0 !important;
  margin-top: 81px;
  margin-bottom: 30px;

  :nth-child(1) {
    border: none;
    height: 29px;
    width: 100px;
    border-radius: 3px;
    background: #F1F1F1;
    color: #326EAC;
  }

  :nth-child(2) {
    height: 29px;
    width: 100px;
    border-radius: 3px;
  }
}

.t-region-filter-modal {
  height: 654px;
}

.t-category-filter-modal {
  height: 446px !important;
}

.profile-modals .modal-content {
  background: white !important;
}

.termine-dropdown-btn {
  color : #FFFFFF !important;
  font-size: 13px !important;
}

.t-termine-dropdown-height {
  max-height: 350px;
}

.termine-dropdown-flags {
  box-shadow: 1px 1px 2px #00000029;
  height:auto;
}