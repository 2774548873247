.analysis-modal {
  //.modal-content {
  //  @media screen and (min-width: 720px) {
  //    width: 720px;
  //  }
  //}

  .modal-body {
    padding: 0 !important;;
  }
}
