@import "src/styles/theme";

@media screen and (min-width:1280px) {
  .market-container{
    max-width: 1280px !important;
  }
  .header{
    max-width: 1280px !important;
  }
  
}
@media screen and (min-width:1100px) and (max-width:1279px) {
  .market-container{
    max-width: 1280px !important;
  }
  .header{
    max-width: 1280px !important;
  }
  
}
@media screen and (max-width:1099px) {
  .market-container{
    max-width: 768px !important;
  }
  .header{
    max-width: 768px !important;
  }
  
}
.market-overview-section {
  //background-color: $dark-background;
  .header {
    //box-shadow: 0 6px 10px -5px rgba(255, 255, 255, 0.22);
    .btn-link {
      color: white;
      fill: white;
    }
  }
  .section-title {
    color: white;
  }
  .market-container {
    box-shadow: rgba(0, 0, 0, 0.56) 0px 11px 70px 4px;
  }
  .sub-navigation {
    .btn-navigation {
      color: white;
      border-bottom: 2px solid transparent;
      &:hover {
        text-decoration: none;
      }
      &.active {
        border-bottom-color: white;
      }
    }
  }
}
