.profile-instrument-dropdown .dropdown-menu {
  min-width: 180px;
  margin-bottom: 12px;
  border: none;
  margin-top: -11px;
  margin-left: 9px;

  h4 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 6px;
  }

  button {
    margin-top: 8px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;

    .svg-icon {
      margin-right: 7px;
    }
  }
}
