.text-anleihen-custom{
    font-size: 15px !important;
    font-family: "Roboto" !important;
    color: #383838 !important;
}
.text-below-table{
    color: #989898 !important;
    font-size: 13px !important;
    text-align: left !important;
    font-weight: 400 !important;
    font-family: "Roboto" !important;
}
.override-button-colors{
    background-color: #ececec !important;
    border-color: #ececec !important;
    color: #326EAC !important;
}