// global variables

$main-hover-transition: 0.3s ease-in-out !important;

$main-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

$spacing-sizes: 1px, 2px, 5px, 10px, 15px, 20px, 25px, 30px, 60px;

$directions: (
    "x": ("left", "right"),
    "y": ("top", "bottom"),
    "t": "top",
    "b": "bottom",
    "l": "left",
    "r": "right"
);

$line-height-sizes: 1px, 15px, 16px, 18px, 20px, 22px, 24px, 30px, 42px
